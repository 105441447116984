import React from "react";
import classnames from "classnames";
import { Link, useHistory } from "react-router-dom";

interface BlockLinkProps {
  icon: React.ReactNode;
  color: string;
  heading: string;
  subHeading: string;
  link?: string;
  onClick?: (link: string) => void;
}

const DisplayBlock = ({ icon, color, heading, subHeading }) => {
  return (
    <div className="h-full flex flex-col items-center py-5 px-2">
      <div
        className={classnames(
          "flex items-center justify-center p-2 text-white rounded-md",
          color
        )}
      >
        {icon}
      </div>
      <div className="text-md text-center font-bold py-2 ">{heading}</div>
      <div className="text-sm text-center">{subHeading}</div>
    </div>
  );
};

const ActionBlockContents = (icon, heading, subHeading) => (
  <>
    <div className="flex items-center justify-center p-2 bg-white text-blue rounded-md">
      {icon}
    </div>
    <div className="text-md text-center font-bold py-2 ">{heading}</div>
    <div className="text-sm text-center">{subHeading}</div>
  </>
);

const ActionBlock = ({ icon, heading, subHeading, link, onClick }) => {
  const history = useHistory();
  const handleClick = () => {
    if (onClick) {
      onClick(link);
    } else {
      history.push(link);
    }
  };

  return (
    <div
      onClick={handleClick}
      className="flex flex-col items-center py-5 px-2 text-white"
    >
      {ActionBlockContents(icon, heading, subHeading)}
    </div>
  );
};

const BlockLink = ({
  icon,
  color,
  heading,
  subHeading,
  link,
  onClick,
}: BlockLinkProps) => {
  return (
    <div className="flex flex-col cursor-pointer">
      <div className="block group-hover:hidden ">
        <DisplayBlock
          icon={icon}
          color={color}
          heading={heading}
          subHeading={subHeading}
        />
      </div>
      <div className="hidden group-hover:block">
        <ActionBlock
          icon={icon}
          heading={heading}
          subHeading={subHeading}
          link={link}
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default BlockLink;
