import React, { FC, ReactElement } from "react";
import { CONFIG } from "../../constants";
import { ReactComponent as WindowsLogoSVG } from "../../assets/windows-logo.svg";

const ssoOptions = [
  {
    icon: <WindowsLogoSVG width={16} height={16} />,
    name: "Microsoft",
    link: `${CONFIG.API_SERVER}admin/sso/microsoft-graph`,
  },
];

export const SSOOptions: FC = () => {
  return (
    <div className="flex flex-row">
      {ssoOptions.map((option, index) => (
        <a
          key={index}
          href={option.link}
          className="w-full bg-white rounded-full py-4 px-3 flex flex-row justify-center items-center space-x-2"
        >
          {option.icon}
          <span className="text-xs text-blue-dark font-bold">
            Login with {option.name}
          </span>
        </a>
      ))}
    </div>
  );
};
