export class NewsModel {
  title: string;
  created: string;
  description: string;
  link: string;
  uuid: string;

  constructor(init: any = {}) {
    this.title = "";
    this.created = "";
    this.description = "";
    this.link = "";
    this.uuid = "";
    Object.assign(this, init);
  }
}
