import React, { FC } from "react";
import { IIconProps } from "./types";
import { Icon } from "./Icon";

export const AccountSwitchIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={22.665} baseHeight={21.162} {...props}>
    {(width, height, color) => (
      <svg
        width={width}
        height={height}
        viewBox="0 0 22.665 21.162"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip-path)">
          <path
            fill={color}
            d="M60.132,46.324a6.358,6.358,0,1,1-4.969,9.391,6.358,6.358,0,0,1,4.969-9.391m2.119,4.664c-.026-.033.112-.618.087-.843-.192-1.732-3.267-1.623-3.256.179,0,.114.111.633.091.66s-.222.087-.261.221a1.706,1.706,0,0,0,.089.9c.065.11.2.179.262.289s.074.26.136.369a4.206,4.206,0,0,1,.4.655,1.1,1.1,0,0,1-.048.688,13.778,13.778,0,0,1-1.857.528c-.26.083-.7.2-.526.573a5.134,5.134,0,0,0,.526.575,4.238,4.238,0,0,0,5.481.113c.262-.217,1.018-.845.546-1.139l-2.2-.647c-.35-.5.107-1.015.339-1.453.053-.1.061-.233.1-.3s.14-.091.2-.176a1.406,1.406,0,0,0,.151-.972c-.047-.209-.247-.2-.263-.219"
            transform="translate(-49.405 -42.053)"
          />
          <path
            fill={color}
            d="M14.177.36,13.853,1.64a11.068,11.068,0,0,0-4.317-.12A9.264,9.264,0,0,0,2.035,12.3a8.162,8.162,0,0,0,.469,1.6c.039.045.059.072.115.025l1.144-.831a.189.189,0,0,1,.268.105l-.138,3.88-.231.116c-1-.356-2.046-.6-3.051-.942-.176-.06-.631-.137-.609-.354.1-.289,1.374-.943,1.377-1.148,0-.081-.393-1.072-.454-1.289A10.542,10.542,0,0,1,6.853.948,11.2,11.2,0,0,1,14.177.36"
            transform="translate(0 -0.001)"
          />
          <path
            fill={color}
            d="M94.425,59.946a9.912,9.912,0,0,0,6.223-.5,9.279,9.279,0,0,0,5.014-11.8c-.038-.029-.28.154-.335.192a9.488,9.488,0,0,1-.869.645c-.14.058-.279.066-.346-.089l.092-3.789.184-.116,3.686,1.089.067.118c0,.193-1.2.884-1.366,1.1a10.458,10.458,0,0,1-1.029,10.13A10.972,10.972,0,0,1,95.418,61.5a7.471,7.471,0,0,1-1.236-.232c-.032-.013-.058-.023-.07-.058Z"
            transform="translate(-85.477 -40.414)"
          />
        </g>
      </svg>
    )}
  </Icon>
);
