import {RelationshipModel} from "./relationship.model";
import {OrganizationDetailModel} from './organization-detail.model';

class DashboardModel {
  calls: string;

  constructor(init: any = {}) {
    Object.assign(this, init)
  }
}

export class OrganizationModel {
  uuid: string;
  parent_uuid: string;
  name: string;
  permissions: string[];
  is_active: boolean;
  details: OrganizationDetailModel;
  dashboard_pages: DashboardModel;
  relationship: RelationshipModel;
  tts_language: string;
  created: string;
  billing_currency: string;
  number_count: number;
  idle_timeout: number;
  whatsapp_account_count: number;

  constructor(init: any = {}) {
    const data = {
      uuid: null,
      parent_uuid: null,
      name: '',
      permissions: [],
      is_active: false,
      relationship: null,
      tts_language: '',
      created: '',
      billing_currency: '',
      number_count: 0,
      idle_timeout: 0,
      whatsapp_account_count: 0,
      ...init,
    };

    this.uuid = data.uuid;
    this.parent_uuid = data.parent_uuid;
    this.name = data.name;
    this.permissions = data.permissions;
    this.is_active = data.is_active;
    this.tts_language = data.tts_language;
    this.number_count = data.number_count;
    this.idle_timeout = data.idle_timeout;
    this.whatsapp_account_count = data.whatsapp_account_count;
    this.created = data.created;
    this.billing_currency = data.billing_currency;
    if (data.details) {
      this.details = new OrganizationDetailModel(data.details);
    }
    if (data.dashboard_pages && Object.keys(data.dashboard_pages).length) {
      this.dashboard_pages = new DashboardModel(data.dashboard_pages);
    }
    if (data.relationship) {
      this.relationship = new RelationshipModel(data.relationship);
    }
  }
}
