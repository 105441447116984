import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  Form,
  FormControl,
  useForm,
  Validators,
} from "../../../../components/ReactiveForm";
import classnames from "classnames";
import { MenuItem, Tab, Tabs } from "@mui/material";
import { Input, Card, Chip, Chips, Button } from "../../../../components";
import { AUDIO_TYPE } from "../../../../utils/enums";
import { AudioModel } from "../../../../utils/types";
import { ConfirmationModal } from "../../../../parts";
import TextSpeechForm from "./TextSpeechForm";
import UploadFileForm from "./UploadFileForm";

export interface IAudioFileFormInitParams {
  getFormData: () => any;
}

export type IAudioFileForm = {
  name: FormControl;
  type: FormControl;
  file: FormControl;
};

export interface IAudioFileFormProps {
  fileType?: string;
  audioFile?: AudioModel;
  confirmCancel?: boolean;
  setTouched?: Dispatch<SetStateAction<boolean>>;
  setAudioTouched?: Dispatch<SetStateAction<boolean>>;
  setForm?: (params: IAudioFileFormInitParams) => void;
  onClose?: () => void;
}

const typeOptions = [
  { text: "Whisper", value: AUDIO_TYPE.WHISPER },
  { text: "Record Call", value: AUDIO_TYPE.RECORD_CALL },
  { text: "Keep Number", value: AUDIO_TYPE.KEEP_NUMBER },
  { text: "Voice Mail", value: AUDIO_TYPE.VOICE_MAIL_LITE },
  { text: "Capture Digits", value: AUDIO_TYPE.CAPTURE_DIGITS },
  { text: "IVR", value: AUDIO_TYPE.IVR },
];

const tabs = [
  { text: "Text to Speech", value: "text" },
  { text: "Upload a File", value: "file" },
];

const AudioFileForm: FC<IAudioFileFormProps> = ({
  audioFile,
  confirmCancel,
  setTouched,
  setAudioTouched,
  fileType = "text",
  setForm = () => {},
  onClose = (bypass?: boolean) => {},
}) => {
  const [previewUrl, setPreviewUrl] = useState<string>("");
  const [type, setType] = useState<string>(fileType);
  const [form, formData] = useForm<IAudioFileForm>({
    name: new FormControl(audioFile?.name || "", [Validators.required()]),
    type: new FormControl(audioFile?.type || AUDIO_TYPE.WHISPER, [
      Validators.required(),
    ]),
    file: new FormControl(null, [Validators.required()]),
  });

  useEffect(() => {
    if (!audioFile) return;
    audioFile.getS3Url().then(({ url }) => setPreviewUrl(url));
  }, []);

  const getFormData = useCallback(() => {
    if (!form.validate()) return null;
    return form.getFormData();
  }, []);

  useEffect(() => {
    setForm({ getFormData });
  }, [formData]);

  return (
    <>
      <Card>
        <Tabs
          value={type}
          classes={{
            root: "!overflow-visible",
            scroller: "!overflow-visible",
            indicator: "!hidden",
          }}
          variant="fullWidth"
          onChange={(_, value) => setType(value)}
        >
          {tabs.map((tab) => (
            <Tab
              data-cy={tab.text}
              key={tab.value}
              className={classnames(
                "!text-base !uppercase",
                type === tab.value
                  ? " !text-white !bg-blue !border-b-2 !border-blue"
                  : "!text-gray-c0"
              )}
              label={tab.text}
              value={tab.value}
            />
          ))}
        </Tabs>
        <div className="flex flex-col px-10">
          {type === "text" && (
            <div className="py-3 text-xs font-normal">
              Create a TTS (Text to Speech) message and select your own voice
              and language preference. To listen to your message before saving,
              click CREATE AUDIO and it will create a preview for you to listen
              to.
            </div>
          )}
          {type !== "text" && (
            <div className="py-3 text-xs font-normal">
              Please upload an audio file from your local device.
            </div>
          )}

          <div className="flex flex-row space-x-8 mt-6">
            <Input
              type="text"
              fullWidth
              control={form.controls.name}
              label="Audio File Name"
              labelClass="text-md font-normal"
              inputClass="!text-sm !font-semibold"
              disabled={!!audioFile}
            />
            <div className="w-full">
              <div className="text-blue-dark font-semibold w-full mb-1">
                Type
              </div>
              <Chips>
                {typeOptions.map((item, i) => (
                  <Chip
                    key={i}
                    className="!text-sm px-3 py-2"
                    active={formData?.type === item.value}
                    disabled={!!audioFile && formData?.type !== item.value}
                    onClick={() => form.controls.type.patch(item.value)}
                  >
                    {item.text}
                  </Chip>
                ))}
              </Chips>
            </div>
          </div>

          {type === "text" ? (
            <TextSpeechForm
              className={type === "text" ? "" : "hidden"}
              form={form}
              previewUrl={previewUrl}
              setPreviewUrl={setPreviewUrl}
              setTouched={setTouched}
            />
          ) : (
            <UploadFileForm
              className={type === "text" ? "hidden" : ""}
              form={form}
              previewUrl={previewUrl}
              setPreviewUrl={setPreviewUrl}
              setAudioTouched={setAudioTouched}
            />
          )}
        </div>
      </Card>
    </>
  );
};

export default AudioFileForm;
