export enum CHAT_INTRO_NOTIFY_TYPES {
  WHATSAPP = "WHATSAPP",
  SMS = "SMS",
  VOICE_CALL = "VOICE_CALL",
  WEBHOOK = "WEBHOOK",
  EMAIL = "EMAIL",
}

export enum CHAT_LOG_NOTIFICATION_STATUS {
  FAILED = "FAILED",
  QUEUED = "QUEUED",
  SENT = "SENT",
  DELIVERED = "DELIVERED",
  READ = "READ",
  DELETED = "DELETED",
  BLACKLISTED_COUNTRY = "BLACKLISTED_COUNTRY",
  BLACKLISTED_NUMBER = "BLACKLISTED_NUMBER",
  BLACKLISTED_KEYWORD = "BLACKLISTED_KEYWORD",
}

export enum CHAT_LOG_STATUS {
  B_PARTY_NOTIFIED = 'BPARTY_NOTIFIED',
  A_PARTY_BLOCKED = 'APARTY_BLOCKED',
  A_PARTY_MESSAGE_RECEIVED = 'APARTY_MESSAGE_RECEIVED',
  B_PARTY_CONFIRMED = 'BPARTY_CONFIRMED',
  A_PARTY_CHAT_LINK_CLICKED = 'APARTY_CHAT_LINK_CLICKED',
  B_PARTY_CONFIRMED_CSAT = 'BPARTY_CONFIRMED_CSAT',
  A_PARTY_NOTIFICATION = 'APARTY_NOTIFICATION',
  A_PARTY_QUALIFYING = 'APARTY_QUALIFYING',
  READY = 'READY',
}

export const messageStatuses = [
  {
    value: CHAT_LOG_NOTIFICATION_STATUS.SENT,
    text: 'Sent'
  },
  {
    value: CHAT_LOG_NOTIFICATION_STATUS.READ,
    text: 'Read'
  },
  {
    value: CHAT_LOG_NOTIFICATION_STATUS.QUEUED,
    text: 'Queued'
  },
  {
    value: CHAT_LOG_NOTIFICATION_STATUS.FAILED,
    text: 'Failed'
  },
  {
    value: CHAT_LOG_NOTIFICATION_STATUS.DELIVERED,
    text: 'Delivered'
  },
  {
    value: CHAT_LOG_NOTIFICATION_STATUS.DELETED,
    text: 'Deleted'
  },
  {
    value: CHAT_LOG_NOTIFICATION_STATUS.BLACKLISTED_NUMBER,
    text: 'Blacklisted Number'
  },
  {
    value: CHAT_LOG_NOTIFICATION_STATUS.BLACKLISTED_COUNTRY,
    text: 'Blacklisted Country'
  },
  {
    value: CHAT_LOG_NOTIFICATION_STATUS.BLACKLISTED_KEYWORD,
    text: 'Blacklisted Keyword'
  },
]

export const chatLogStatuses = [
  {
    value: CHAT_LOG_STATUS.B_PARTY_NOTIFIED,
    text: 'Agent Notified/Not Responded',
    color: 'text-blue',
  },
  {
    value: CHAT_LOG_STATUS.A_PARTY_BLOCKED,
    text: 'Enquirer Blocked',
    color: 'text-danger-light',
  },
  {
    value: CHAT_LOG_STATUS.A_PARTY_MESSAGE_RECEIVED,
    text: 'Delivered to Enquirer',
    color: 'text-success',
  },
  {
    value: CHAT_LOG_STATUS.B_PARTY_CONFIRMED,
    text: 'Sales Agent Responded',
    color: 'text-blue',
  },
  {
    value: CHAT_LOG_STATUS.A_PARTY_CHAT_LINK_CLICKED,
    text: 'Enquirer Initiated Message',
    color: 'text-blue',
  },
  {
    value: CHAT_LOG_STATUS.B_PARTY_CONFIRMED_CSAT,
    text: 'CSAT Survey Was Sent',
    color: 'text-blue',
  },
  {
    value: CHAT_LOG_STATUS.A_PARTY_NOTIFICATION,
    text: 'Enquirer Notification',
    color: 'text-blue',
  },
  {
    value: CHAT_LOG_STATUS.A_PARTY_QUALIFYING,
    text: 'Enquirer Qualifying',
    color: 'text-blue',
  },
  {
    value: CHAT_LOG_STATUS.READY,
    text: 'Ready',
    color: 'text-blue',
  },
];
