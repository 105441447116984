import React, { FC } from "react";
import { IIconProps } from "./types";
import { Icon } from "./Icon";

export const ApiIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={11.25} baseHeight={15.495} {...props}>
    {(width, height, color) => (
      <svg
        width={width}
        height={height}
        viewBox="0 0 11.25 15.495"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="scale(0.7273)">
          <path
            id="Icon_material-developer-mode"
            data-name="Icon material-developer-mode"
            d="M8.905,5.374H18.59V7.311h1.937V3.437A1.934,1.934,0,0,0,18.59,1.51L8.905,1.5A1.943,1.943,0,0,0,6.968,3.437V7.311H8.905ZM17.05,16.6,21.5,12.153,17.05,7.708,15.685,9.083l3.07,3.07-3.07,3.07L17.05,16.6Zm-5.239-1.375-3.07-3.07,3.07-3.07L10.445,7.708,6,12.153,10.445,16.6Zm6.779,3.709H8.905V17H6.968v3.874a1.943,1.943,0,0,0,1.937,1.937H18.59a1.943,1.943,0,0,0,1.937-1.937V17H18.59Z"
            transform="translate(-6 -1.5)"
            fill={color}
          />
        </g>
      </svg>
    )}
  </Icon>
);
