import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { ROUTES } from "../../../../constants";
import { onCloseConfirmation } from "../../../../utils/helpers/confirm-cancel.helper";
import { ErrorNotificationModel } from "../../../../utils/types";
import { CloseCircleIcon, TrashIcon } from "../../../../components/icons";
import ErrorNotificationForm, {
  IErrorNotificationFormInitParams,
} from "../ErrorNotificationForm";
import { ErrorManagementService } from "../../../../services/error-management.service";
import { ToastService } from "../../../../services";
import { Button } from "../../../../components";
import { SEND_PERIOD } from "../../../../utils/enums";

export const ErrorNotificationEdit = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [errorNotification, setErrorNotification] =
    useState<ErrorNotificationModel>();
  const [touched, setTouched] = useState<boolean>(false);
  const [errorNotificationTouched, setErrorNotificationTouched] =
    useState<boolean>(false);
  const [showConfirmCancel, setShowConfirmCancel] = useState<boolean>(false);
  const [editForm, setEditForm] = useState<IErrorNotificationFormInitParams>({
    getFormData: () => null,
  });

  React.useEffect(() => {
    if (id) {
      ErrorManagementService.getErrorNotification(id, true)
        .then((data) => {
          setErrorNotification(data);
        })
        .catch((err) => {
          ToastService.showHttpError(err, "Failed to load blocked number.");
        });
    }
  }, [id]);

  const onClose = (bypass?: boolean) => {
    onCloseConfirmation(
      bypass,
      touched,
      errorNotificationTouched,
      setShowConfirmCancel,
      history,
      ROUTES.DEVELOPER.ERROR_NOTIFICATIONS.INDEX
    );
  };

  const onEdit = () => {
    const data = editForm.getFormData();
    if (data) {
      if (data.send_period !== SEND_PERIOD.DAILY) {
        data.gmt_hour = null;
      }
      ErrorManagementService.updateErrorNotication(
        errorNotification.org_user_uuid,
        data
      )
        .then(() => {
          onClose(true);
        })
        .catch((err) => {
          ToastService.showHttpError(
            err,
            "Failed to update error notification"
          );
        });
    }
  };

  const onDelete = () => {
    ErrorManagementService.deleteErrorNotification(
      errorNotification.org_user_uuid,
      true
    )
      .then(() => {
        onClose();
      })
      .catch((err) => {
        ToastService.showHttpError(err, "Failed to delete error notification");
      });
  };

  return (
    <div className="relative z-100 w-full h-full flex flex-col">
      <div className="flex items-center justify-between bg-blue-semidark text-white py-1.5 px-6">
        <div className="font-semibold text-lg uppercase pt-3 pb-2">
          Edit Error Notication
        </div>
        <CloseCircleIcon
          color="white"
          size={32}
          className="cursor-pointer"
          onClick={() => setShowConfirmCancel(true)}
        />
      </div>
      <div className="h-0 flex-grow overflow-auto my-4 mx-26">
        <ErrorNotificationForm
          setForm={setEditForm}
          errorNotification={errorNotification}
          confirmCancel={showConfirmCancel}
          onClose={onClose}
        />
        <div className="flex flex-row bg-inherit ">
          <Button
            data-cy="error-notification-cancel"
            className="text-sm rounded-full mr-4"
            color="danger"
            variant="outline"
            leftIcon={<TrashIcon size={12} />}
            onClick={onDelete}
          >
            Delete Notification
          </Button>
          <Button
            data-cy="error-notification-save"
            className="text-sm rounded-full px-6 py-2 ml-auto"
            color="primary"
            onClick={onEdit}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};
