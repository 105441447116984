import Tooltip from '@mui/material/Tooltip';
import classnames from 'classnames';
import React from "react";
import { useSelector } from "react-redux";
import { Avatar, DataTable, IDataTableColumn, IDataTableRef, Menu, SelfHelpButton, StatusBadge, WithTooltipIcon } from "../../../components";
import { BarChartIcon, BriefcaseIcon, ErrorCircleIcon, HealthIcon, InboxIcon, MoreVertIcon } from "../../../components/icons";
import { CONFIG } from "../../../constants";
import { getActiveOrganization } from "../../../redux/selectors";
import { WhatsappService } from "../../../services";
import { formatPhoneNumber, fromNow } from "../../../utils/helpers";
import { WhatsappAccountModel } from "../../../utils/types";
import { AccountHealth } from './AccountHealth';
import { AccountNotFound } from "./AccountNotFound";
import AccountViewModal from "./AccountViewModal";

const Accounts = () => {
  const organization = useSelector(getActiveOrganization);
  const [accountsCount, setAccountsCount] = React.useState<number>();
  const [viewAccount, setViewAccount] = React.useState<WhatsappAccountModel>();
  const [viewHealth, setViewHealth] = React.useState<WhatsappAccountModel>();
  const [tableRef, setTableRef] = React.useState<IDataTableRef>();

  const onDelete = () => {
    setViewAccount(undefined)
  }

  const columns = React.useMemo<IDataTableColumn<WhatsappAccountModel>[]>(() => [
    {
      title: '',
      cellClass: '!px-4',
      headerClass: '!px-4',
      render(row) {
        return (
          <div className={classnames(
            'w-3 h-3 rounded-full mx-auto',
            { 'bg-green shadow-md': row.isNew() },
          )} />
        );
      },
    },
    {
      title: 'Display Name',
      field: 'name',
      headerClass: '!pl-16',
      tooltip: { title: 'Display Name' },
      render(row: WhatsappAccountModel) {
        return (
          <div className="flex items-center">
            <Avatar
              src={row.profile_picture}
              background="#e5e5e5"
              alt={<BriefcaseIcon className="text-gray-f8" size={18} />}
            />
            <span className="ml-4">{row.display_name}</span>
            {row.is_active && (
              <span className="w-2 h-2 bg-[#72C421] rounded-full ml-2.5" />
            )}
            {row.number_health === "ORANGE" &&
              <WithTooltipIcon
                tooltip={'NUMBER HEALTH ALERT \n Your number health is currently compromised. Please click on the link below to view your number health in META'}
                placement='bottom'
                icon={
                  <ErrorCircleIcon
                    color={'orange'}
                    size={18}
                  />
                } />
            }
            {row.number_health === "RED" &&
              <WithTooltipIcon
                tooltip={'NUMBER HEALTH ALERT \n Your number health is currently compromised. Please click on the link below to view your number health in META'}
                placement='bottom'
                icon={
                  <ErrorCircleIcon
                    color={'red'}
                    size={18}
                  />
                } />
            }
          </div>
        );
      },
    },
    {
      title: 'WhatsApp Number',
      field: 'whatsapp_number',
      tooltip: { title: 'WhatsApp Number' },
      render(row: WhatsappAccountModel) {
        return (
          <span className="text-blue-dark font-semibold">
            {formatPhoneNumber(row.whatsapp_number)}
          </span>
        );
      },
    },
    {
      title: 'FB Business Manager ID',
      field: 'fb_business_manager_id',
      tooltip: { title: 'FB Business Manager ID' },
    },
    {
      title: 'Number of Chat Intros',
      field: 'chat_intro_count',
      tooltip: { title: 'Number of Chat Intros' },
      align: 'center',
    },
    {
      title: 'Date Created',
      field: 'created',
      render(row: WhatsappAccountModel) {
        return fromNow(row.created);
      },
    },
    {
      render(row: WhatsappAccountModel) {
        return (
          <div className="flex items-center justify-end gap-1.5">
            {row.isNew() &&
              <StatusBadge text={`I'm New`} color={'#7FD93D'} />
              // <div className='rounded-md bg-[#DDF6CD] text-[#7FD93D] uppercase p-2 !font-bold max-w-fit'>I'm New</div>
            }
            {row.campaign_mode &&
              <StatusBadge text={'Campaign'} color={'#79D7EE'} />
              // <div className='rounded-md bg-sky bg-opacity-26 text-sky uppercase p-2 !font-bold max-w-fit'>Campaign</div>
            }
            {row.chatwoot_inbox &&
              <Tooltip title="Go to Inbox" arrow placement="top">
                <a
                  data-cy="wa-goto-inbox"
                  href={`${CONFIG.INBOX_URL}/app/accounts/${row.chatwoot_inbox?.account_id}/inbox/${row.chatwoot_inbox?.inbox_id}`}
                  target="_blank"
                  rel="noreferrer"
                  className="w-7.5 h-7.5 flex-center flex-shrink-0 bg-primary bg-opacity-26 text-primary rounded-md cursor-pointer"
                >
                  <InboxIcon size={16} />
                </a>
              </Tooltip>}
            {row.dashboard_pages?.advanced_report &&
              <Tooltip title="See Advanced Reporting" arrow placement="top">
                <a
                  data-cy="wa-advanced-reporting"
                  href={`${row.dashboard_pages?.advanced_report}`}
                  target="_blank"
                  rel="noreferrer"
                  className="w-7.5 h-7.5 flex-center flex-shrink-0 bg-primary bg-opacity-26 text-primary rounded-md cursor-pointer"
                >
                  <BarChartIcon size={15} />
                </a>
              </Tooltip>}
            <Tooltip title="View Health" arrow placement="top">
              <div
                data-cy="wa-view-health"
                className="w-7.5 h-7.5 flex-center flex-shrink-0 bg-blue bg-opacity-26 text-blue rounded-md cursor-pointer"
                onClick={() => setViewHealth(row)}
              >
                <HealthIcon size={16} />
              </div>
            </Tooltip>
            <Menu
              className="ml-2"
              text={<MoreVertIcon color="blue-dark" />}
              arrow={null}
              items={[
                { text: 'WhatsApp Profile Details', value: 'block' },
                // { text: 'Add a tag', value: 'add-tag' },
              ]}
              menuItemClass="!text-xs"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onSelectItem={() => setViewAccount(row)}
            />
          </div>
        );
      },
    },
  ], []);

  const loadAccounts = React.useCallback((options, callback) => {
    WhatsappService.searchAccounts({
      page: options.page + 1,
      page_size: options.perPage,
    }, false).then((data) => {
      setAccountsCount(data.count);
      callback({
        count: data.count,
        data: data.results,
      });
    }).catch(() => {
      setAccountsCount(0);
      callback({
        count: 0,
        data: [],
      });
    });
  }, [organization]);

  const refreshTable = React.useCallback(() => {
    if (tableRef) {
      tableRef.refresh();
    }
  }, [tableRef]);

  const EmptyComponent = () => {
    return (
      <AccountNotFound onSuccess={refreshTable} />
    )
  }

  return (
    <>
      <div data-cy="wa-accounts-dt" className="relative h-full flex flex-col px-7 py-9">
        <div className='flex justify-end my-4'>
          <SelfHelpButton url={'/docs/chat-inbox'} />
        </div>
        <DataTable
          wrapperClass="h-full"
          columns={columns}
          datasource={loadAccounts}
          serverSide
          pagination="auto"
          rowsPerPage={50}
          size="xs"
          CustomEmptyComponent={EmptyComponent}
          showCustomEmptyComponent={true}
          stickyHeader
          stripped
          headerCellClass="!text-13p"
          rowClass={(row) => classnames({ '!bg-green !bg-opacity-11': row.isNew() })}
          cellClass={(row) => classnames('px-8 !text-11p', { '!font-semibold': row.isNew() })}
        />
      </div>

      {viewAccount && (
        <AccountViewModal
          account={viewAccount}
          onDelete={() => onDelete()}
          onClose={() => setViewAccount(undefined)}
        />
      )}

      {viewHealth && (
        <AccountHealth
          account={viewHealth}
          onClose={() => setViewHealth(undefined)}
        />
      )}
    </>
  );
};

export default Accounts;
