import React, { useEffect, useState } from "react";
import { Button } from "../../components";
import { ROUTES, CONFIG } from "../../constants";
import {
  ApiIcon,
  HashTagIcon,
  InboxIcon,
  PhoneInTalkIcon,
  SmsIcon,
  WhatsAppIcon,
} from "../../components/icons";
import { Link, useHistory } from "react-router-dom";
import classnames from "classnames";
import { UserService, AuthService } from "../../services";
import { NewsModel } from "../../utils/types";

import { getActiveOrganization } from "../../redux/selectors";
import { useSelector } from "react-redux";
import BlockLink from "../../components/BlockLink";

interface ActionBlock {
  icon: React.ReactNode;
  color: string;
  heading: string;
  subHeading: string;
  link: string;
  onClick?: (link: string) => void;
}

const DashboardPage = () => {
  const history = useHistory();
  const activeOrganization = useSelector(getActiveOrganization);
  const [news, setNews] = useState<NewsModel[]>([]);

  const onClickInboxSSO = (link: string) => {
    UserService.getInboxSSO().then(res => {
      window.open(res.url, '_blank');
    });
  };

  const onClickReadmeLink = (link: string = "") => {
    AuthService.readme_login(link).then(res => {
      window.open(res.url, '_blank');
    });
  };

  const actionBlocks: ActionBlock[] = [
    {
      icon: <InboxIcon size={20} />,
      color: "bg-blue",
      heading: "Go to your inbox",
      subHeading: "Need a shortcut to your Message Inbox? This is it",
      link: "",
      onClick: onClickInboxSSO,
    },
    {
      icon: <PhoneInTalkIcon size={20} />,
      color: "bg-primary",
      heading: "Recent Calls",
      subHeading: "View your most recent received calls from our call section",
      link: ROUTES.CALLS.INDEX,
    },
    {
      icon: <HashTagIcon size={20} />,
      color: "bg-sky",
      heading: "Your numbers",
      subHeading: "View all your numbers",
      link: ROUTES.NUMBERS.INDEX,
    },
    {
      icon: <WhatsAppIcon size={20} />,
      color: "bg-green",
      heading: "Go to your leads",
      subHeading:
        "Have incoming chat intro logs ? Head over to your WhatsApp section.",
      link: ROUTES.WHATSAPP.CHAT_LOGS.INDEX,
    },
    {
      icon: <SmsIcon size={20} />,
      color: "bg-purple",
      heading: "Start a campaign",
      subHeading: "Start sending campaign messages from our WhatsApp section",
      link: ROUTES.WHATSAPP.CAMPAIGNS.INDEX,
    },
    {
      icon: <ApiIcon size={15} />,
      color: "bg-black",
      heading: "Dev looking for API's",
      subHeading:
        "Head over to our developer section. You will find everything.",
      link: "",
      onClick: onClickReadmeLink,
    },
  ];

  useEffect(() => {
    UserService.getNews().then((res) => {
      setNews(res);
    });
  }, []);

  const NotificationBlock = ({ heading, description, date, link }) => {
    const onClick = (redirect: string = "") => {
      window.open(redirect);
    };
    return (
      <div className="w-full p-3 bg-white rounded-md shadow-md">
        <div className="text-md font-bold py-2 ">{heading}</div>
        <div>{description}</div>
        <div className="flex flex-row space-between w-full items-center py-2">
          <div className="text-sm text-gray-b7">
            {new Date(date).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            })}
          </div>
          <Button onClick={() => onClick(link)} className="ml-auto">
            <div className="text-primary font-bold">Read More</div>
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="grid grid-cols-12 gap-4 h-full">
      <div className="tablet-landscape:col-span-8 px-5 pt-5 col-span-12">
        <div className="bg-opacity-mask mask-shape1 bg-gradient-to-b from-[#1A477F] to-[#1C56A1] text-white rounded-md shadow-md p-6">
          <div className="relative">
            <h2 className="text-2xl font-bold">
              Welcome back to your dashboard
            </h2>
            <div className="xs:flex items-start">
              <div className="mr-1">
                <div className="w-6 h-1 bg-primary mt-1" />
                <p className="text-xs font-medium mt-2">
                  Want to see{" "}
                  <span className="font-bold">what we are all about?</span>
                  <br />
                  Head over to our documentation section and start exploring
                </p>
              </div>
              <Button
                className="ml-auto flex-shrink-0 text-sm rounded-full shadow-md px-6 max-xs:mt-4 max-xs:mx-auto"
                color="primary"
                onClick={() => history.push(ROUTES.DEVELOPER.INDEX)}
              >
                Check out docs
              </Button>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-4 py-5">
          {actionBlocks.map((block, i) => (
            <div
              key={i}
              className="rounded-md shadow-md bg-white hover:bg-blue tablet-landscape:col-span-4 col-span-12 flex flex-col group"
            >
              <BlockLink
                icon={block.icon}
                color={block.color}
                heading={block.heading}
                subHeading={block.subHeading}
                link={block.link}
                onClick={block.onClick}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="hidden col-span-4 tablet-landscape:block py-5 bg-gray-ea">
        <div className="flex flex-col space-y-5 border px-5">
          <h1 className="text-2xl font-bold text-center">What's new?</h1>
          {news.map((news, i) => (
            <NotificationBlock
              key={i}
              heading={news.title}
              description={news.description}
              date={news.created}
              link={news.link}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
