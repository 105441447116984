import React from "react";
import { useIdleTimerContext } from "../../providers/InactivityProvider";
import { Button, Modal } from "../../components";
import { AuthService } from "../../services";

const InactivityAlert: React.FC = () => {
  const { showWarning, originalTimeout, resetTimer, timeLeft, handleLogout } =
    useIdleTimerContext();

  if (!showWarning) {
    return null;
  }

  return (
    <Modal
      title={"Inactivity Alert"}
      onClose={resetTimer}
      disableBackdropClose
      hasCloseButton={false}
    >
      <div className="flex flex-col">
        <div>
          <p>You have been inactive for more than {originalTimeout} minutes!</p>
          {timeLeft > 0 ? (
            <p>
              You will be logged out in <strong>{timeLeft}</strong> seconds. Are
              you still here?
            </p>
          ) : (
            <div>
              <p>Logging you out...</p>
            </div>
          )}
        </div>
        <div className="flex items-center justify-center">
          <div className="text-7xl text-blue-dark mt-10">
            {timeLeft > 9 ? "00:" : "00:0"}
            {timeLeft}
          </div>
        </div>
        <div className="flex ml-auto mt-10 space-x-4">
          <Button onClick={() => handleLogout()} className="text-primary">
            Log Out
          </Button>
          <Button
            onClick={resetTimer}
            className="text-sm rounded-full px-6 py-2"
            color="primary"
          >
            I'm still here
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default InactivityAlert;
