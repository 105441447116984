import React from "react";
import { Button } from "../../../components";
import {
  ArrowRightCircleIcon,
  CoinsIcon,
  FileTextIcon,
  HashTagIcon,
  WhatsAppIcon,
  WhatsAppOutlineIcon,
} from "../../../components/icons";
import { AuthService } from "../../../services";
import BlockLink from "../../../components/BlockLink";

export const WhatsAppDocumentation = () => {
  const onClick = (redirect: string) => {
    AuthService.readme_login(redirect).then((res) => {
      window.open(res.url);
    });
  };

  return (
    <div className="developer-documentation px-10 py-4">
      <div className="grid grid-cols-12 gap-4 mt-8">
        <div className="col-span-12 md:col-span-8 xl:col-span-6 bg-opacity-mask mask-shape1 bg-gradient-to-b from-[#1A477F] to-[#1C56A1] text-white rounded-md shadow-md p-10">
          <div className="relative">
            <h2 className="text-2xl font-bold">
              Getting you <span className="text-primary">started</span>
            </h2>
            <div className="xs:flex items-center">
              <div className="mr-2">
                <div className="w-6 h-1 bg-primary mt-1" />
                <p className="text-xs font-medium mt-2">
                  Not sure where to start? Have a look at our WhatsApp
                  Documentation and guides to help you navigate your way through
                  the WhatsApp world
                </p>
              </div>
              <Button
                className="flex-shrink-0 text-sm !font-semibold rounded-full shadow-md px-6 max-xs:mt-4 max-xs:mx-auto"
                color="primary"
                onClick={() => onClick("/docs/whatsapp-chat-intros")}
              >
                Let's get started
              </Button>
            </div>
          </div>
        </div>

        <div className="col-span-12 sm:col-span-4 xl:col-span-3 bg-white hover:bg-blue  rounded-md shadow-md px-4 pt-4 pb-2 group">
          <BlockLink
            icon={<FileTextIcon size={20} />}
            color="bg-blue"
            heading="Message Templates"
            subHeading="Want to know more about setting up your own message template?"
            onClick={() =>
              window.open(
                "https://business.facebook.com/wa/manage/message-templates/",
                "_blank",
                "noreferrer"
              )
            }
          />
        </div>

        <div className="col-span-12 sm:col-span-4 xl:col-span-3 bg-white hover:bg-blue  rounded-md shadow-md px-4 pt-4 pb-2 group">
          <BlockLink
            icon={<WhatsAppOutlineIcon size={25} />}
            color="bg-[#69D35F]"
            heading="WhatsApp Account"
            subHeading="Everything you need to know about Your WhatsApp Account on META"
            onClick={() =>
              window.open(
                "https://business.facebook.com/latest/settings/whatsapp_account",
                "_blank",
                "noreferrer"
              )
            }
          />
        </div>

        <div className="col-span-12 sm:col-span-4 xl:col-span-3 bg-white hover:bg-blue  rounded-md shadow-md px-4 pt-4 pb-2 group">
          <BlockLink
            icon={<HashTagIcon size={25} />}
            color="bg-sky"
            heading="Your WhatsApp Numbers"
            subHeading="Everything you need to know about Your WhatsApp Numbers on META"
            onClick={() =>
              window.open(
                "https://business.facebook.com/wa/manage/phone-numbers/",
                "_blank",
                "noreferrer"
              )
            }
          />
        </div>

        <div className="col-span-12 md:col-span-8 xl:col-span-6 bg-opacity-mask mask-shape1 bg-gradient-to-b from-[#1A477F] to-[#1C56A1] text-white rounded-md shadow-md py-12 px-10">
          <div className="relative">
            <h2 className="text-2xl font-bold">
              Need some <span className="text-primary">insights?</span>
            </h2>
            <div className="xs:flex items-center">
              <div className="mr-2">
                <div className="w-6 h-1 bg-primary mt-1" />
                <p className="text-xs font-medium mt-2">
                  WhatsApp can be daunting, but it shouldn't be! Have a look at
                  some key insight on META.
                </p>
              </div>
              <a
                className="flex-shrink-0 text-sm !font-semibold rounded-full shadow-md px-6 py-2 max-xs:mt-4 max-xs:mx-auto uppercase bg-primary"
                href="https://business.facebook.com/wa/manage/insights/"
                target="_blank"
                rel="noreferrer"
              >
                Have a look
              </a>
            </div>
          </div>
        </div>

        <div className="col-span-12 sm:col-span-4 xl:col-span-3 bg-white hover:bg-blue  rounded-md shadow-md px-4 pt-4 pb-2 group">
          <BlockLink
            icon={<CoinsIcon size={20} />}
            color="bg-purple"
            heading="META Billing"
            subHeading="Everything you need to know about your billing in META"
            onClick={() =>
              window.open(
                "https://business.facebook.com/billing_hub/accounts",
                "_blank",
                "noreferrer"
              )
            }
          />
        </div>
      </div>
    </div>
  );
};
