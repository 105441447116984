import React, { useCallback, useEffect } from "react";
import classnames from "classnames";

import {
  Chip,
  DataTable,
  IDataTableColumn,
  SelfHelpButton,
} from "../../../components";
import { ErrorNotificationModel } from "../../../utils/types";
import { ErrorManagementService } from "../../../services/error-management.service";
import { EditIcon, PlusIcon } from "../../../components/icons";
import { ROUTES } from "../../../constants";
import { Link, useHistory } from "react-router-dom";
import { Tooltip } from "@mui/material";

const ErrorNotifications = () => {
  const [errorNotifications, setErrorNotifications] = React.useState<
    ErrorNotificationModel[]
  >([]);

  const history = useHistory();

  const loadErrorNotifications = useCallback(() => {
    ErrorManagementService.getErrorNotifications().then((res) =>
      setErrorNotifications(res)
    );
  }, []);

  useEffect(() => {
    loadErrorNotifications();
  }, [loadErrorNotifications]);

  const columns = React.useMemo<IDataTableColumn<ErrorNotificationModel>[]>(
    () => [
      {
        title: "Email address",
        field: "email_address",
        render(row: ErrorNotificationModel) {
          return <span>{row.user_email}</span>;
        },
      },

      {
        title: "Error Category",
        field: "error_category",
        render(row: ErrorNotificationModel) {
          return (
            <div className="flex items-center">
              {row.error_categories.map((item, index) => (
                <Chip key={index} active containerClass="mr-2">
                  {item}
                </Chip>
              ))}
            </div>
          );
        },
      },
      {
        render(row: ErrorNotificationModel) {
          return (
            <div className="flex items-center">
              <Tooltip title="Edit Error Notification" arrow placement="left">
                <Link
                  data-cy="edit-number"
                  className="w-7.5 h-7.5 flex-center flex-shrink-0 bg-blue-lighter rounded-md cursor-pointer"
                  to={`${ROUTES.DEVELOPER.ERROR_NOTIFICATIONS.PREFIX}/${row.org_user_uuid}/edit`}
                >
                  <EditIcon color="blue" size={22} />
                </Link>
              </Tooltip>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div className="relative h-full flex flex-col px-7 py-9">
      <DataTable
        wrapperClass="h-0 flex-grow mt-6"
        columns={columns}
        data={errorNotifications}
        cellClass="!px-4"
        stickyHeader
        stripped
      />
      <div
        data-cy="create-api-token"
        className={classnames(
          "plus-action fixed bottom-5 right-5 w-13.5 hover:w-62 h-13.5 flex items-center overflow-hidden px-3",
          "bg-primary text-white font-medium uppercase whitespace-nowrap rounded-full shadow-md transition-all cursor-pointer"
        )}
        onClick={() =>
          history.push(ROUTES.DEVELOPER.ERROR_NOTIFICATIONS.CREATE)
        }
      >
        <PlusIcon color="white" size={30} />
        <span className="ml-3">Create Notification</span>
      </div>
    </div>
  );
};
export default ErrorNotifications;
