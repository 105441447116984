import React, { ReactNode, useEffect, useMemo, useState } from "react";
import qs from "qs";
import { useLocation } from "react-router";
import { Link, useHistory } from "react-router-dom";
import PasswordStrengthBar from "react-password-strength-bar";
import { Button } from "../../../components";
import { CheckCircleOutlineIcon } from "../../../components/icons";
import {
  Form,
  FormControl,
  Input,
  useForm,
  Validators,
} from "../../../components/ReactiveForm";
import { ROUTES } from "../../../constants";
import SimpleLayout from "../../../layouts/SimpleLayout";
import { AuthService, ToastService } from "../../../services";

const ResetPassword = () => {
  const [form, formData] = useForm<{
    password: FormControl;
    confirmPassword: FormControl;
  }>({
    password: new FormControl("", [Validators.required()]),
    confirmPassword: new FormControl("", [Validators.required()]),
  });
  const { search } = useLocation();
  const history = useHistory();
  const [step, setStep] = useState(0);
  const [passwordScore, setPasswordScore] = useState(0);
  const [countdown, setCountdown] = useState(5);

  const { uid64, token, context } = useMemo(
    () => qs.parse(search.replace(/\?/g, "")),
    [search]
  );

  const texts =
    context === "new-user"
      ? {
          button: "Set Password",
          heading: "Password Set",
          success: "Your password has been set successfully",
        }
      : {
          button: "Reset Password",
          heading: "Password Reset",
          success: "Your password has been reset successfully",
        };

  const newUserText = (
    <div className="flex flex-col">
      <p
        className="text-5xl font-bold text-white text-left leading-tight"
        style={{ lineHeight: "1.2" }}
      >
        Welcome to Sudonum
      </p>
      <p className="text-white text-19p mt-4">
        To <span className="text-primary"> activate</span> your account,
        <br />
        please <span className="text-primary">set a new password</span>
      </p>
    </div>
  );

  const existingUserText = (
    <div className="flex items-center justify-center">
      <p
        className="text-5xl font-bold text-white text-left leading-tight"
        style={{ lineHeight: "1.2" }}
      >
        Let's reset your password
      </p>
    </div>
  );

  const userText: ReactNode =
    context === "new-user" ? newUserText : existingUserText;

  useEffect(() => {
    const confirmPasswordValidator = ({ value }: FormControl) => {
      if (
        form.controls.password.value &&
        value &&
        form.controls.password.value !== value
      )
        return `Passwords do not match.`;
      return null;
    };
    form.controls.confirmPassword.validators.push(confirmPasswordValidator);
  }, [form]);

  useEffect(() => {
    form.controls.password.setOptions({
      validators: [
        ({ value }: FormControl) => {
          if (!value) {
            return `This field is required.`;
          }
          if (passwordScore < 2) {
            return `Please input strong password.`;
          }
          return null;
        },
      ],
    });
  }, [form, passwordScore]);

  const onResetPassword = (formData) => {
    const { password, confirmPassword } = formData;
    AuthService.confirmResetPassword(password, confirmPassword, uid64, token)
      .then(() => {
        ToastService.success("Your password has been reset successfully");
        setStep(1);
      })
      .catch((err) => {
        ToastService.showHttpError(err, "Reset Password failed");
      });
  };

  useEffect(() => {
    let timer;
    if (step === 1) {
      timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            history.push(ROUTES.AUTH.LOGIN);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => timer && clearInterval(timer);
  }, [step, history]);

  return (
    <SimpleLayout text={userText} contentClass="w-85">
      {step === 0 && (
        <Form className="" formGroup={form} onSubmit={onResetPassword}>
          <p className="text-white text-18p font-bold mb-4">
            Please {context === "new-user" ? "set your" : "enter your new"}{" "}
            password
          </p>

          <Input
            control={form.controls.password}
            type="password"
            placeholder="Password"
            fullWidth
          />
          <Input
            control={form.controls.confirmPassword}
            type="password"
            containerClass="-mt"
            placeholder="Confirm password"
            fullWidth
          />
          <PasswordStrengthBar
            className="mt-2"
            password={formData?.password || ""}
            onChangeScore={setPasswordScore}
          />

          <div className="flex items-center mt-6">
            <Button
              type="submit"
              color="primary"
              className="w-full text-sm rounded-full shadow-md px-4 ml-2"
            >
              {texts.button}
            </Button>
          </div>
        </Form>
      )}

      {step === 1 && (
        <div className="text-blue-dark text-center">
          <CheckCircleOutlineIcon className="text-green-light" size={140} />
          <h1 className="text-4xl font-bold mt-2">{texts.heading}</h1>
          <p className="mt-1">{texts.success}</p>

          <p>You will be redirected to log in</p>

          <div className="text-5xl text-blue-dark mt-10">00:0{countdown}</div>

          <div className="mt-10">
            Ready to
            <Link
              className="text-primary font-semibold hover:underline mx-1"
              to={ROUTES.AUTH.LOGIN}
            >
              log in
            </Link>
            again?
          </div>
        </div>
      )}
    </SimpleLayout>
  );
};

export default ResetPassword;
