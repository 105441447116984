import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import classnames from "classnames";
import Tooltip from "@mui/material/Tooltip";
import {
  DataTable,
  IDataTableColumn,
  IMenuItem,
  Menu,
} from "../../../components";
import {
  InfoCircleIcon,
  MoreVertIcon,
  TagsIcon,
} from "../../../components/icons";
import { AddTagModal } from "../../../parts";
import MessageDetailModal from "../MessageDetailModal";
import ReplyMessageModal from "../ReplyMessageModal";
import { setSelectedSmsLogs, setSmsLogs } from "../../../redux/actions";
import {
  getActiveOrganization,
  getSelectedSmsLogs,
  getSmsFilters,
  getSmsTab,
} from "../../../redux/selectors";
import { SmsService, ToastService } from "../../../services";
import { ISearchMessagesRequest } from "../../../utils/dto";
import { SMS_STATUS, SMS_TAB } from "../../../utils/enums";
import { formatDateTime, formatPhoneNumber } from "../../../utils/helpers";
import { SmsRecordModel } from "../../../utils/types";

let lastQuery: any;
const rowsPerPage = 50;

export interface IMessageTableProps {
  onBlockNumbers(message: SmsRecordModel): void;
  onUnBlockNumbers(message: SmsRecordModel): void;
  onQueryChange(query: any): void;
  newMessageSent: boolean;
  setNewMessageSent(value: boolean): void;
}

const MessagesTable: FC<IMessageTableProps> = ({
  onBlockNumbers,
  onUnBlockNumbers,
  onQueryChange,
  setNewMessageSent,
  newMessageSent = false,
}) => {
  const dispatch = useDispatch();
  const tab = useSelector(getSmsTab);
  const filters = useSelector(getSmsFilters);
  const selectedLogs = useSelector(getSelectedSmsLogs);
  const organization = useSelector(getActiveOrganization);

  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<SmsRecordModel[]>([]);
  const [filteredData, setFilteredData] = useState<SmsRecordModel[]>([]);
  const [viewMessage, setViewMessage] = useState<SmsRecordModel>();
  const [replyMessage, setReplyMessage] = useState<SmsRecordModel>();
  const [addTagMessage, setAddTagMessage] = useState<SmsRecordModel>();
  const [touched, setTouched] = useState<boolean>(false);
  const [showConfirmCancel, setShowConfirmCancel] = useState<boolean>(false);

  useEffect(() => {
    lastQuery = null;
  }, []);

  useEffect(() => {
    setPage(0);
    dispatch(setSelectedSmsLogs([]));
  }, [tab]);

  useEffect(() => {
    if (newMessageSent) {
      refreshTable();
      setNewMessageSent(false);
    }
  }, [newMessageSent]);

  const columns = useMemo<IDataTableColumn<SmsRecordModel>[]>(() => {
    return [
      {
        title: "Date and Time",
        field: "time",
        tooltip: {
          title:
            "The local time and date the message was " +
            (tab === SMS_TAB.SENT ? "sent" : "received"),
        },
        render(row: SmsRecordModel) {
          return formatDateTime(row.time);
        },
      },
      {
        title: `Sender ${search && "(works just like Caller status on calls)"}`,
        field: "from_number",
        tooltip: {
          title:
            tab === SMS_TAB.SENT
              ? "The number the message was sent from"
              : "The number that sent the message",
        },
        render(row: SmsRecordModel) {
          return formatPhoneNumber(row.from_number);
        },
      },
      ...(tab === SMS_TAB.SENT
        ? []
        : [
            {
              title: "Sudo Number",
              field: "sudo",
              cellClass: "!font-semibold",
              tooltip: {
                title:
                  "The number you created that was messaged/displayed instead of your personal number",
              },
              render(row: SmsRecordModel) {
                if (!row.number) {
                  return null;
                }
                return (
                  <Link to={`/numbers/${row.number}`}>
                    <div className="text-primary text-11p">
                      {formatPhoneNumber(row.number)}
                    </div>
                    <div className="text-9p">{row.meta?.name || ""}</div>
                  </Link>
                );
              },
            },
          ]),
      {
        title: "Target Number",
        field: "target_number",
        tooltip: {
          title: "The number the message is forwarded to",
        },
        render(row: SmsRecordModel) {
          return formatPhoneNumber(row.target_number);
        },
      },
      {
        title: "Message",
        field: "message",
        tooltip: {
          title:
            tab === SMS_TAB.SENT
              ? "A snippet of the message content sent"
              : "A snippet of the message content received",
        },
        render(row: SmsRecordModel) {
          return <p className="max-w-80 truncate">{row.message}</p>;
        },
      },
      {
        title: "Status",
        field: "status",
        tooltip: {
          title: "Status indicates if your message was received or delivered",
        },
        render(row: SmsRecordModel) {
          return (
            <span
              className={classnames(
                "font-medium",
                {
                  "text-green-dark bg-success bg-opacity-18 p-2 rounded-md !font-semibold":
                    row.status === SMS_STATUS.DELIVERED ||
                    row.status === SMS_STATUS.RECEIVED ||
                    row.status === SMS_STATUS.SENT,
                },
                {
                  "text-primary bg-primary-light bg-opacity-85 p-2 rounded-md !font-semibold":
                    row.status.includes(SMS_STATUS.BLOCKED) ||
                    row.status === SMS_STATUS.FAILED,
                }
              )}
            >
              {row.status === SMS_STATUS.BLOCKED_SENDER
                ? "Blocked Number"
                : row.status === SMS_STATUS.BLOCKED_KEYWORD
                ? "Blocked Keyword(s)"
                : row.status}
            </span>
          );
        },
      },
      {
        render(row: SmsRecordModel) {
          const menuItems: IMenuItem[] = [];

          if (tab === SMS_TAB.RECEIVED) {
            menuItems.push({ text: "Reply to sender", value: "reply" });
            menuItems.push({
              text: "Mark this number as spam",
              value: "spam-number",
            });
          }

          if (tab === SMS_TAB.BLOCKED) {
            if (row.status === SMS_STATUS.BLOCKED_SENDER) {
              menuItems.push({
                text: "Unblock this number",
                value: "unblock-number",
              });
            }
            /* if (row.status === SMS_STATUS.BLOCKED_KEYWORD) {
              menuItems.push({ text: 'Unblock this keyword(s)', value: 'unblock-keywords' });
              } */
          }

          return (
            <div className="flex items-center justify-end">
              <TagsIcon
                className={
                  row.tags?.length
                    ? "cursor-pointer"
                    : "opacity-0 pointer-events-none"
                }
                color="blue"
                size={18}
              />
              <Tooltip title="View Details" arrow placement="top">
                <div className="w-7.5 h-7.5 flex-center flex-shrink-0 bg-blue-lighter rounded-md ml-3 cursor-pointer">
                  <InfoCircleIcon
                    color="blue"
                    size={18}
                    onClick={() => setViewMessage(row)}
                  />
                </div>
              </Tooltip>
              {menuItems.length > 0 && (
                <Menu
                  className="ml-2"
                  text={<MoreVertIcon color="blue-dark" />}
                  arrow={null}
                  items={menuItems}
                  menuClass="rounded"
                  menuItemClass="!text-xs !font-medium"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  onSelectItem={(option) => handleRowAction(row, option.value)}
                />
              )}
            </div>
          );
        },
      },
    ];
  }, [tab, search]);

  useEffect(() => {
    const filter = filters.find((item) => item.category === "number");
    setSearch(filter?.value || "");
  }, [filters]);

  const loadData = useCallback((query) => {
    setLoading(true);
    SmsService.search(query, false)
      .then((data) => {
        setData(data.results);
        setTotalCount(data.count);
        dispatch(setSmsLogs(data.results));
        setLoading(false);
      })
      .catch(() => {
        setData([]);
        setTotalCount(0);
        dispatch(setSmsLogs([]));
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!filters.length) {
      return;
    }

    const query: ISearchMessagesRequest = {
      page: page + 1,
      page_size: rowsPerPage,
    };
    if (tab !== SMS_TAB.BLOCKED) {
      query.not_statuses = [
        SMS_STATUS.BLOCKED_SENDER,
        SMS_STATUS.BLOCKED_KEYWORD,
      ].join();
    }

    filters.forEach((filter) => {
      if (!filter.value) {
        return;
      }

      switch (filter.category) {
        case "range": {
          query.start_date = moment(filter.value.startDate)
            .utc()
            .format("YYYY-MM-DD HH:mm:ss");
          query.end_date = moment(filter.value.endDate)
            .utc()
            .format("YYYY-MM-DD HH:mm:ss");
          break;
        }

        case "number":
          break;

        case "status": {
          const field = tab === SMS_TAB.BLOCKED ? "status" : "statuses";
          query[field] = Array.isArray(filter?.value)
            ? filter?.value?.join()
            : filter?.value;
          break;
        }

        default:
          query[filter.category] = filter.value;
      }
    });

    lastQuery = query;
    loadData(query);
    onQueryChange(query);
  }, [page, rowsPerPage, filters, tab, organization, loadData, onQueryChange]);

  useEffect(() => {
    if (!search) {
      setFilteredData(data);
    } else {
      const keywords = search.replace(/\D/g, "");
      setFilteredData(data.filter((item) => item.number?.includes(keywords)));
    }
  }, [search, data]);

  const setSelectedMessages = useCallback((messages: SmsRecordModel[]) => {
    dispatch(setSelectedSmsLogs(messages));
  }, []);

  const refreshTable = () => {
    loadData(lastQuery);
  };

  const handleRowAction = (message: SmsRecordModel, action: string) => {
    switch (action) {
      case "unblock-number": {
        onUnBlockNumbers(message);
        return;
      }
      case "spam-number": {
        onBlockNumbers(message);
        return;
      }
      /* case 'spam-keywords': {
        onBlockKeywords([message]);
        return;
        } */
      case "add-tag": {
        setAddTagMessage(message);
        return;
      }
      case "reply": {
        setReplyMessage(message);
        return;
      }
    }
  };

  const onCloseReplyMessageModal = (result) => {
    if (!result) {
      setShowConfirmCancel(false);
    }

    if (result) {
      setShowConfirmCancel(false);
      refreshTable();
      setReplyMessage(undefined);
      return;
    }

    if (result === undefined) {
      setReplyMessage(undefined);
    }
  };

  const onAddTags = () => {
    ToastService.success(
      <>
        <span className="text-success mr-1">Successfully</span> added a tag.
      </>
    );
    refreshTable();
  };

  return (
    <>
      <DataTable
        wrapperClass="h-0 flex-grow mt-3"
        columns={columns}
        serverSide={false}
        data={filteredData}
        totalCount={totalCount}
        extLoading={loading}
        size="xs"
        stripped
        stickyHeader
        // checkboxSelection
        selectedRows={selectedLogs}
        isEqual={(a, b) => a.uuid === b.uuid}
        page={page}
        pagination
        selfPagination
        rowsPerPage={rowsPerPage}
        paginationClass="mr-16"
        onSelectionChange={setSelectedMessages}
        onPaginationChange={(page) => setPage(page)}
      />

      {viewMessage && (
        <MessageDetailModal
          message={viewMessage}
          onClose={() => setViewMessage(null)}
        />
      )}
      {addTagMessage && (
        <AddTagModal
          tags={addTagMessage.tags}
          onSubmit={onAddTags}
          onClose={() => setAddTagMessage(null)}
        />
      )}
      {replyMessage && (
        <ReplyMessageModal
          receivedMessage={replyMessage}
          onClose={onCloseReplyMessageModal}
          setTouched={setTouched}
          setShowConfirmCancel={setShowConfirmCancel}
          touched={touched}
          showConfirmCancel={showConfirmCancel}
        />
      )}
    </>
  );
};

export default MessagesTable;
