import classnames from "classnames";
import React, { FC } from "react";

interface IStatusBadgeProps {
    text: string;
    className?: string;
    color?: string;
}

export const StatusBadge: FC<IStatusBadgeProps> = ({
    text = '',
    className = '',
    color = '#307CD8',
}) => {
    return (
        <div
            style={{ color: `${color}`, backgroundColor: `${color}26` }}
            className={
                classnames(
                    `rounded-md uppercase p-2 !font-bold max-w-fit`,
                    className,
                )}>
            {text}
        </div>
    )
}