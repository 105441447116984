import React, { FC } from "react";
import { IIconProps } from "./types";
import { Icon } from "./Icon";

export const HealthIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={15.05} baseHeight={15.05} {...props}>
    {(width, height, color = '#307cd8') => (
      <svg width={width} height={height} viewBox="0 0 15.05 15.05">
        <path
          d="M15.77,5.737a.314.314,0,0,0-.314-.314H11.38a.314.314,0,0,1-.314-.314V1.034A.314.314,0,0,0,10.753.72H5.737a.314.314,0,0,0-.314.314V5.109a.314.314,0,0,1-.314.314H1.034a.314.314,0,0,0-.314.314v5.017a.314.314,0,0,0,.314.314H5.109a.314.314,0,0,1,.314.314v4.076a.314.314,0,0,0,.314.314h5.017a.314.314,0,0,0,.314-.314V11.38a.314.314,0,0,1,.314-.314h4.076a.314.314,0,0,0,.314-.314Z"
          transform="translate(-0.72 -0.72)"
          fill={color} />
      </svg>
    )}
  </Icon>
);
