import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import { DataTable, IDataTableColumn } from "../../../../components";
import { getActiveOrganization } from "../../../../redux/selectors";
import { GraphSearchIcon } from "../../../../components/icons";
import { ROUTES } from "../../../../constants";
import {
  copyToClipboard,
  formatPhoneNumber,
  fromNow,
} from "../../../../utils/helpers";
import { WAChatIntroModel } from "../../../../utils/types";

const rowsPerPage = 50;

export interface IChatIntroTableProps {
  className?: string;
  referenceId?: string;
  filters: any[];
  onQueryChange(query: any): void;
}

const ChatIntrosTable: FC<IChatIntroTableProps> = ({
  className,
  referenceId,
  filters,
  onQueryChange,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const organization = useSelector(getActiveOrganization);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [chatIntros, setChatIntros] = useState<WAChatIntroModel[]>([]);
  const [loading, setLoading] = useState(false);

  const onViewLogs = (row: WAChatIntroModel) => {
    history.push(
      `${ROUTES.WHATSAPP.CHAT_LOGS.INDEX}?chat_intro_uuid=${row.uuid}`
    );
  };

  const columns = useMemo<IDataTableColumn<WAChatIntroModel>[]>(
    () => [
      {
        title: "Chat Intro Link",
        field: "chat_intro_link",
        tooltip: { title: "Chat Intro Link" },
        render(row: WAChatIntroModel) {
          return (
            <div
              className="has-hover-action hover:text-blue cursor-pointer"
              onClick={() => copyToClipboard(row.chat_link)}
            >
              <span className="text-2xs">{row.chat_link}</span>
              <i className="far fa-clone pnh-transparent ml-2 transition-all" />
            </div>
          );
        },
      },
      {
        title: "WhatsApp Account",
        field: "whatsapp_account",
        tooltip: { title: "WhatsApp Account" },
        cellClass: "!pl-7",
        headerClass: "!pl-7",
        render(row: WAChatIntroModel) {
          return (
            <span className="text-xs font-semibold">
              {formatPhoneNumber(row.whatsapp_number)}
            </span>
          );
        },
      },
      {
        title: "Reference ID",
        field: "reference_id",
        tooltip: { title: "Reference ID" },
        render(row: WAChatIntroModel) {
          return row.reference_id || "-";
        },
      },
      {
        title: "Notification Type",
        field: "notify_type",
        tooltip: { title: "Chat Intros notification type" },
      },
      {
        title: "Notification Destination",
        field: "notified_destination",
        tooltip: { title: "Destination of the Notification" },
        render(row: WAChatIntroModel) {
          return (
            <span className="text-xs">
              {formatPhoneNumber(row.notified_destination)}
            </span>
          );
        },
      },
      {
        title: "Date Created",
        field: "created_at",
        tooltip: { title: "Date Created" },
        render(row: WAChatIntroModel) {
          return fromNow(row.created);
        },
      },
      {
        cellClass: "!pr-7",
        render(row: WAChatIntroModel) {
          return (
            <Tooltip title="View chat intro logs" arrow placement="left">
              <div
                className="w-7.5 h-7.5 flex-center flex-shrink-0 bg-blue-lighter text-blue rounded-md ml-auto cursor-pointer"
                onClick={() => onViewLogs(row)}
              >
                <GraphSearchIcon size={22} />
              </div>
            </Tooltip>
          );
        },
      },
    ],
    [filters, page, organization]
  );

  const loadChatIntros = useCallback((query) => {
    setLoading(true);
    WAChatIntroModel.search(query, false)
      .then((data) => {
        setChatIntros(data.results);
        setTotalCount(data.count);
        setLoading(false);
      })
      .catch(() => {
        setChatIntros([]);
        setTotalCount(0);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const query: any = {
      page: page + 1,
      page_size: rowsPerPage,
    };

    const usable_filters = filters.filter((x) => x.value);
    if (!usable_filters.length && filters.length) return;

    usable_filters.forEach((filter) => {
      switch (filter.category) {
        case "account":
          query.account = filter.value.replace(/\D/g, "");
          break;

        case "number_notified":
          query.number_notified = filter.value.replace(/\D/g, "");
          break;

        default:
          query[filter.category] = filter.value;
      }
    });

    if (referenceId) {
      query.reference_id = referenceId;
    }
    loadChatIntros(query);
  }, [referenceId, columns]);

  return (
    <>
      <DataTable
        wrapperClass={className}
        columns={columns}
        serverSide={false}
        data={chatIntros}
        totalCount={totalCount}
        extLoading={loading}
        size="xs"
        stripped
        stickyHeader
        page={page}
        rowsPerPage={rowsPerPage}
        pagination
        selfPagination
        headerCellClass="!text-13p"
        cellClass="!text-11p"
        onPaginationChange={(page) => setPage(page)}
      />
    </>
  );
};

export default ChatIntrosTable;
