import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { Button, Card, HorizontalStepper, Modal, TwoFactorInput } from "../../../components";
import { SwitchSmall } from "../../../components/SwitchSmall";
import { CheckCircleIcon } from "../../../components/icons";
import { TwoFactorAuthenticationSvg } from "../../../components/svgs/TwoFactorAuthentication";
import { AuthService, ToastService, UserService } from "../../../services";
import { UserModel } from "../../../utils/types";

const TwoFactorAuthentication = () => {
  const tfaSteps = [1, 2, 3]

  const ref = useRef<HTMLDivElement>();
  const [activeStep, setActiveStep] = useState(0);
  const [showTfaModal, setShowTfaModal] = useState(false);
  const [totp_device, setTotpDevice] = useState(null);
  const [user, setUser] = useState<UserModel>(null);
  const [modalProps, setModalProps] = useState({ title: '', content: '', callback: (t: string) => { } });

  const loadTotpDevices = () => {
    return new Promise((resolve, reject) => {
      AuthService.totpGet("default").then(data => {
        setTotpDevice(data);
        resolve(data);
      }).catch(() => {
        reject();
      })
    })
  }

  const loadUser = () => {
    return new Promise<UserModel>((resolve, reject) => {
      UserService.getProfile().then(data => {
        setUser(data);
        resolve(data);
      }).catch(() => {
        ToastService.error('Failed fetch user.');
        reject();
      })
    })
  }

  useEffect(() => {
    loadUser()
      .then(u => { if (u.totp_enabled) setActiveStep(3) })
      .catch(() => setActiveStep(0))
  }, [])

  useEffect(() => {
    if (activeStep === 1) {
      AuthService.getQRCode(false).then(data => {
        const myImg = document.getElementById('qrcode-img') as HTMLImageElement
        myImg.setAttribute('src', "data:image/png;base64, " + data)
      })
    }
    else if (activeStep === 3) loadTotpDevices();
  }, [activeStep])

  const onNext = () => {
    setActiveStep(activeStep + 1);
  };

  const onBack = () => {
    setActiveStep(activeStep - 1);
  };

  const verifyTFA = token => {
    return new Promise<void>((resolve, reject) => {
      AuthService.totpVerify(token, false).then(() => {
        resolve();
      }).catch(err => {
        reject();
      })
    })
  }

  const onDisableModalShow = device => {
    setShowTfaModal(true);
    setModalProps({
      title: 'Disable two factor authentication',
      content: 'Are you sure you want to disable your 2FA? If so, please give us your secret code.',
      callback: token => {
        AuthService.totpDisable(token)
          .then(() => { setShowTfaModal(false); setActiveStep(0) })
          .catch(err => { ToastService.showHttpError(err, "Failed to disable TOTP.") })
      },
    })
  }

  const SuccessCard = () => {
    return (
      <div>
        <Card size="sm" className="m-8 min-w-[18rem]">
          <div className="flex flex-row items-center">
            <p className="font-bold text-xs pr-3">Two Factor Authentication</p>
            <p className="text-xs text-secondary pr-2">enabled</p>
            <CheckCircleIcon className="text-green" size={14} />
            <div className="flex flex-row ml-auto mr-0">
              <SwitchSmall
                value={user?.totp_enabled}
                onChange={() => { onDisableModalShow(totp_device) }}
              />
            </div>
          </div>
        </Card>
      </div>
    )
  }

  const Step1Page = (
    <>
      <p className="font-bold">1. Get an Authenticator App</p>
      <br />
      <p>An Authenticator app lets you generate security codes on your phone without needing to receive text messages. If you don't already have one, <span className="font-bold">we suggest Google Authenticator.</span></p>
    </>
  )

  const Step2Page = (
    <>
      <p className="font-bold">2. Scan the QR code</p>
      <br />
      <p>To configure your authenticator app, add a new time-based token and scan the barcode below.</p>
      <br />
      <img id="qrcode-img" width="217" height="217" alt="Loading..." />
    </>
  )

  const Step3Page = (
    <>
      <p className="font-bold">3. Enter your secret code</p>
      <br />
      <p>Please enter your time based code below.</p>
      <div className="pt-8">
        <TwoFactorInput onComplete={token => { verifyTFA(token).then(() => { loadUser(); onNext(); }).catch(err => { ToastService.error('Failed to verify.'); }) }} />
      </div>
    </>
  )

  const Footer = (
    <div className="flex justify-end absolute bottom-0 right-0 py-10 px-16">
      {
        activeStep < 3 &&
        <>
          {
            activeStep > 0 &&
            <Button type="submit" className="rounded-full pr-4" onClick={onBack}>
              <p className="text-primary">Back</p>
            </Button>
          }
          {
            activeStep === 0 &&
            <Button type="submit" color="primary" className="rounded-full shadow-md" onClick={onNext}>Cool, I have one</Button>
          }
          {
            activeStep === 1 &&
            <Button type="submit" color="primary" className="rounded-full shadow-md" onClick={onNext}>Next</Button>
          }
        </>
      }
    </div>
  )

  const TFAModal = (
    <Modal
      title={modalProps.title}
      wrapperClass="!max-w-[736px] h-fit"
      hasCloseButton
      onClose={() => {
        setShowTfaModal(false)
      }}
    >
      <div className="mx-auto flex flex-col justify-center">
        <p className="">{modalProps.content}</p>
        <div className="py-14">
          <TwoFactorInput
            onComplete={modalProps.callback}
            secondary
          />
        </div>
      </div>
    </Modal>
  )

  return (
    <>
      <div className="h-full grid grid-rows-12">
        <div className="row-span-1 pt-20" >
          <p className="text-blue font-bold text-4xl text-center py-4">Enable Two Factor Authentication</p>
        </div>
        <div className="row-span-1 grid grid-cols-2">
          <div className="w-3/4 col-start-2">
            <HorizontalStepper activeStep={activeStep} steps={tfaSteps} />
          </div>
        </div>
        <div className="row-span-5 grid grid-cols-2">
          <div className="flex justify-end">
            <TwoFactorAuthenticationSvg className="justify-end pr-12" />
          </div>
          <div className={classNames(activeStep !== 2 ? "w-3/4" : "w-full")}>
            {activeStep === 0 && Step1Page}
            {activeStep === 1 && Step2Page}
            {activeStep === 2 && Step3Page}
            {activeStep === 3 && SuccessCard()}
          </div>
        </div>
        {Footer}
      </div >
      {showTfaModal && TFAModal}
    </>
  )
}

export default TwoFactorAuthentication;
