import React from "react";
import { Route, Switch } from "react-router";
import { ROUTES } from "../../constants";
import DashboardPage from "./index";

const DashboardRouting = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.DASH.INDEX} component={DashboardPage} />
    </Switch>
  );
};

export default DashboardRouting;
