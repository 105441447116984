import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { ErrorManagementService } from "../../../../services/error-management.service";
import { CloseCircleIcon } from "../../../../components/icons";
import { ROUTES } from "../../../../constants";
import { onCloseConfirmation } from "../../../../utils/helpers/confirm-cancel.helper";
import ErrorNotificationForm, {
  IErrorNotificationFormInitParams,
} from "../ErrorNotificationForm";
import { ToastService } from "../../../../services";
import { Button } from "../../../../components";
import { SEND_PERIOD } from "../../../../utils/enums";

export const ErrorNotificationCreate = () => {
  const history = useHistory();
  const [touched, setTouched] = useState<boolean>(false);
  const [errorNotificationTouched, setErrorNotificationTouched] =
    useState<boolean>(false);
  const [showConfirmCancel, setShowConfirmCancel] = useState<boolean>(false);

  const [createForm, setCreateForm] =
    useState<IErrorNotificationFormInitParams>({
      getFormData: () => null,
    });

  const onClose = (bypass?: boolean) => {
    onCloseConfirmation(
      bypass,
      touched,
      errorNotificationTouched,
      setShowConfirmCancel,
      history,
      ROUTES.DEVELOPER.ERROR_NOTIFICATIONS.INDEX
    );
  };

  const onCreate = () => {
    const data = createForm.getFormData();
    if (data) {
      if (data.send_period !== SEND_PERIOD.DAILY) {
        data.gmt_hour = null;
      }
      ErrorManagementService.createErrorNotification(data)
        .then(() => {
          ToastService.success(
            <>
              <span className="text-success mr-1">Successfully</span> created
              new error notification.
            </>
          );
          onClose(true);
        })
        .catch((err) => {
          ToastService.showHttpError(err, "Creating error notification failed");
        });
    }
  };

  return (
    <div className="relative z-100 w-full h-full flex flex-col">
      <div className="flex items-center justify-between bg-blue-semidark text-white py-1.5 px-6">
        <div className="font-semibold text-lg uppercase pt-3 pb-2">
          Add Error Notication
        </div>
        <CloseCircleIcon
          color="white"
          size={32}
          className="cursor-pointer"
          onClick={() => setShowConfirmCancel(true)}
        />
      </div>
      <div className="h-0 flex-grow overflow-auto my-4 mx-26">
        <ErrorNotificationForm
          setForm={setCreateForm}
          onClose={onClose}
          confirmCancel={showConfirmCancel}
        />
        <div className="flex flex-row bg-inherit justify-end">
          <Button
            data-cy="error-notification-cancel"
            className="!font-semibold text-primary mr-2"
            onClick={() => setShowConfirmCancel(true)}
          >
            Cancel
          </Button>
          <Button
            data-cy="error-notification-save"
            className="text-sm rounded-full px-8 py-2 !font-semibold shadow-md"
            color="primary"
            onClick={onCreate}
          >
            Create
          </Button>
        </div>
      </div>
    </div>
  );
};
