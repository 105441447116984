import { HttpService } from "./http.service";
import {
  NewsModel,
  OrganizationModel,
  ProjectModel,
  UserModel,
} from "../utils/types";
import {
  ICompanyRequest,
  ICreateUserRequest,
  IUserProfile,
} from "../utils/dto";

export class UserService {
  static search(
    orgUuid: string,
    email = "",
    showSpinner = true
  ): Promise<UserModel[]> {
    let payload: any = {};
    if (email) payload.email = email;

    return HttpService.get(
      `/admin/org/${orgUuid}/user`,
      payload,
      {},
      showSpinner
    ).then((res) => res.results.map((item) => new UserModel(item)));
  }

  static find(
    orgUuid: string,
    uuid: string,
    showSpinner = true
  ): Promise<UserModel> {
    return HttpService.get(
      `/admin/org/${orgUuid}/user/${uuid}`,
      {},
      {},
      showSpinner
    ).then((res) => new UserModel(res));
  }

  static update(
    orgUuid: string,
    uuid: string,
    data: Partial<UserModel>,
    showSpinner = true
  ): Promise<UserModel> {
    return HttpService.put(
      `/admin/org/${orgUuid}/user/${uuid}`,
      data,
      {},
      showSpinner
    ).then((res) => new UserModel(res));
  }

  static findOrganizations(
    uuid: string,
    showSpinner = true
  ): Promise<ProjectModel[]> {
    return HttpService.get(`/admin/user/${uuid}/org`, {}, {}, showSpinner).then(
      (res) => res.results.map((item) => new ProjectModel(item))
    );
  }

  static create(
    orgUuid: string,
    data: ICreateUserRequest,
    showSpinner = true
  ): Promise<UserModel> {
    return HttpService.post(
      `/admin/org/${orgUuid}/invite`,
      data,
      {},
      showSpinner
    ).then((res) => new UserModel(res));
  }

  static getCompanyDetail(
    orgUuid: string,
    showSpinner = true
  ): Promise<OrganizationModel> {
    return HttpService.get(`/admin/org/${orgUuid}`, {}, {}, showSpinner).then(
      (res) => new OrganizationModel(res)
    );
  }

  static setCompanyDetail(
    orgUuid: string,
    data: ICompanyRequest,
    showSpinner = true
  ): Promise<OrganizationModel> {
    return HttpService.put(`/admin/org/${orgUuid}`, data, {}, showSpinner).then(
      (res) => new OrganizationModel(res)
    );
  }

  static getProfile(showSpinner = true): Promise<UserModel> {
    return HttpService.get(`/admin/user`, {}, {}, showSpinner).then(
      (res) => new UserModel(res)
    );
  }

  static getInboxSSO(showSpinner = true): Promise<any> {
    return HttpService.get(`/admin/sso/chatwoot`, {}, {}, showSpinner);
  }

  static getNews(showSpinner = true): Promise<NewsModel[]> {
    return HttpService.get(`/admin/news`, {}, {}, showSpinner).then((res) =>
      res.results.map((item) => new NewsModel(item))
    );
  }

  static modifyProfile(data, showSpinner = true): Promise<UserModel> {
    return HttpService.patch(`/admin/user`, data, {}, showSpinner).then(
      (res) => new UserModel(res)
    );
  }
}
