import React, { useMemo, useState } from "react";
import classnames from "classnames";
import { Tooltip } from "@mui/material";
import { DataTable, IDataTableColumn, TextField } from "../../../components";
import { EditIcon, PlusIcon, SearchIcon } from "../../../components/icons";
import {
  BLOCKED_KEYWORD_CHANNEL,
  BLOCKED_KEYWORD_CHANNEL_VALUES,
  BLOCKED_KEYWORD_TYPE,
} from "../../../utils/enums";
import { BlockedKeywordModel } from "../../../utils/types";
import { Link, useHistory } from "react-router-dom";
import { ROUTES } from "../../../constants";

const data: BlockedKeywordModel[] = [
  {
    uuid: "1",
    keywords: "This is keywords",
    type: BLOCKED_KEYWORD_TYPE.EXACT,
    channels: [BLOCKED_KEYWORD_CHANNEL.SMS],
    blockedBy: "Marisha van der Merwe",
  },
  {
    uuid: "2",
    keywords: "This is keywords",
    type: BLOCKED_KEYWORD_TYPE.EXACT,
    channels: [BLOCKED_KEYWORD_CHANNEL.WHATSAPP],
    blockedBy: "Marisha van der Merwe",
  },
  {
    uuid: "3",
    keywords: "This is keywords",
    type: BLOCKED_KEYWORD_TYPE.EXACT,
    channels: [BLOCKED_KEYWORD_CHANNEL.SMS],
    blockedBy: "Marisha van der Merwe",
  },
  {
    uuid: "4",
    keywords: "This is keywords",
    type: BLOCKED_KEYWORD_TYPE.EXACT,
    channels: [BLOCKED_KEYWORD_CHANNEL.SMS],
    blockedBy: "Marisha van der Merwe",
  },
  {
    uuid: "5",
    keywords: "This is keywords",
    type: BLOCKED_KEYWORD_TYPE.EXACT,
    channels: [BLOCKED_KEYWORD_CHANNEL.WHATSAPP],
    blockedBy: "Marisha van der Merwe",
  },
  {
    uuid: "6",
    keywords: "This is keywords",
    type: BLOCKED_KEYWORD_TYPE.EXACT,
    channels: [BLOCKED_KEYWORD_CHANNEL.SMS],
    blockedBy: "Marisha van der Merwe",
  },
  {
    uuid: "7",
    keywords: "This is keywords",
    type: BLOCKED_KEYWORD_TYPE.EXACT,
    channels: [BLOCKED_KEYWORD_CHANNEL.SMS],
    blockedBy: "Marisha van der Merwe",
  },
  {
    uuid: "8",
    keywords: "This is keywords",
    type: BLOCKED_KEYWORD_TYPE.EXACT,
    channels: [BLOCKED_KEYWORD_CHANNEL.SMS],
    blockedBy: "Marisha van der Merwe",
  },
  {
    uuid: "9",
    keywords: "This is keywords",
    type: BLOCKED_KEYWORD_TYPE.EXACT,
    channels: [BLOCKED_KEYWORD_CHANNEL.SMS],
    blockedBy: "Marisha van der Merwe",
  },
  {
    uuid: "10",
    keywords: "This is keywords",
    type: BLOCKED_KEYWORD_TYPE.EXACT,
    channels: [BLOCKED_KEYWORD_CHANNEL.SMS],
    blockedBy: "Marisha van der Merwe",
  },
  {
    uuid: "11",
    keywords: "This is keywords",
    type: BLOCKED_KEYWORD_TYPE.EXACT,
    channels: [BLOCKED_KEYWORD_CHANNEL.SMS],
    blockedBy: "Marisha van der Merwe",
  },
  {
    uuid: "12",
    keywords: "This is keywords",
    type: BLOCKED_KEYWORD_TYPE.EXACT,
    channels: [BLOCKED_KEYWORD_CHANNEL.SMS],
    blockedBy: "Marisha van der Merwe",
  },
  {
    uuid: "13",
    keywords: "This is keywords",
    type: BLOCKED_KEYWORD_TYPE.EXACT,
    channels: [BLOCKED_KEYWORD_CHANNEL.SMS],
    blockedBy: "Marisha van der Merwe",
  },
  {
    uuid: "14",
    keywords: "This is keywords",
    type: BLOCKED_KEYWORD_TYPE.EXACT,
    channels: [BLOCKED_KEYWORD_CHANNEL.SMS],
    blockedBy: "Marisha van der Merwe",
  },
  {
    uuid: "15",
    keywords: "This is keywords",
    type: BLOCKED_KEYWORD_TYPE.EXACT,
    channels: [BLOCKED_KEYWORD_CHANNEL.SMS],
    blockedBy: "Marisha van der Merwe",
  },
  {
    uuid: "16",
    keywords: "This is keywords",
    type: BLOCKED_KEYWORD_TYPE.EXACT,
    channels: [BLOCKED_KEYWORD_CHANNEL.SMS],
    blockedBy: "Marisha van der Merwe",
  },
  {
    uuid: "17",
    keywords: "This is keywords",
    type: BLOCKED_KEYWORD_TYPE.EXACT,
    channels: [BLOCKED_KEYWORD_CHANNEL.SMS],
    blockedBy: "Marisha van der Merwe",
  },
  {
    uuid: "18",
    keywords: "This is keywords",
    type: BLOCKED_KEYWORD_TYPE.EXACT,
    channels: [BLOCKED_KEYWORD_CHANNEL.SMS],
    blockedBy: "Marisha van der Merwe",
  },
  {
    uuid: "19",
    keywords: "This is keywords",
    type: BLOCKED_KEYWORD_TYPE.EXACT,
    channels: [BLOCKED_KEYWORD_CHANNEL.SMS],
    blockedBy: "Marisha van der Merwe",
  },
  {
    uuid: "20",
    keywords: "This is keywords",
    type: BLOCKED_KEYWORD_TYPE.EXACT,
    channels: [BLOCKED_KEYWORD_CHANNEL.SMS],
    blockedBy: "Marisha van der Merwe",
  },
  {
    uuid: "21",
    keywords: "This is keywords",
    type: BLOCKED_KEYWORD_TYPE.EXACT,
    channels: [BLOCKED_KEYWORD_CHANNEL.SMS],
    blockedBy: "Marisha van der Merwe",
  },
  {
    uuid: "22",
    keywords: "This is keywords",
    type: BLOCKED_KEYWORD_TYPE.EXACT,
    channels: [BLOCKED_KEYWORD_CHANNEL.SMS],
    blockedBy: "Marisha van der Merwe",
  },
  {
    uuid: "23",
    keywords: "This is keywords",
    type: BLOCKED_KEYWORD_TYPE.EXACT,
    channels: [BLOCKED_KEYWORD_CHANNEL.SMS],
    blockedBy: "Marisha van der Merwe",
  },
  {
    uuid: "24",
    keywords: "This is keywords",
    type: BLOCKED_KEYWORD_TYPE.EXACT,
    channels: [BLOCKED_KEYWORD_CHANNEL.SMS],
    blockedBy: "Marisha van der Merwe",
  },
  {
    uuid: "25",
    keywords: "This is keywords",
    type: BLOCKED_KEYWORD_TYPE.EXACT,
    channels: [BLOCKED_KEYWORD_CHANNEL.SMS],
    blockedBy: "Marisha van der Merwe",
  },
  {
    uuid: "26",
    keywords: "This is keywords",
    type: BLOCKED_KEYWORD_TYPE.EXACT,
    channels: [BLOCKED_KEYWORD_CHANNEL.SMS],
    blockedBy: "Marisha van der Merwe",
  },
  {
    uuid: "27",
    keywords: "This is keywords",
    type: BLOCKED_KEYWORD_TYPE.EXACT,
    channels: [BLOCKED_KEYWORD_CHANNEL.SMS],
    blockedBy: "Marisha van der Merwe",
  },
  {
    uuid: "28",
    keywords: "This is keywords",
    type: BLOCKED_KEYWORD_TYPE.EXACT,
    channels: [BLOCKED_KEYWORD_CHANNEL.SMS],
    blockedBy: "Marisha van der Merwe",
  },
  {
    uuid: "29",
    keywords: "This is keywords",
    type: BLOCKED_KEYWORD_TYPE.EXACT,
    channels: [BLOCKED_KEYWORD_CHANNEL.SMS],
    blockedBy: "Marisha van der Merwe",
  },
  {
    uuid: "30",
    keywords: "This is keywords",
    type: BLOCKED_KEYWORD_TYPE.EXACT,
    channels: [BLOCKED_KEYWORD_CHANNEL.SMS],
    blockedBy: "Marisha van der Merwe",
  },
];

const BlockedKeywords = () => {
  const [search, setSearch] = useState("");
  const [creatingKeyword, setCreatingKeyword] = useState(false);
  const history = useHistory();

  const columns = useMemo<IDataTableColumn<BlockedKeywordModel>[]>(
    () => [
      {
        title: "Keywords",
        field: "keywords",
        cellClass: "!text-primary !font-medium",
      },
      {
        title: "Channels",
        field: "channels",
        render(row: BlockedKeywordModel) {
          return (
            <div className="flex items-center">
              {row.channels.map((c, i) => {
                return (
                  <div
                    className={classnames(
                      "bg-blue-100 rounded-lg px-2 py-2 mr-1 inline-block",
                      "text-blue-600 font-bold text-xs"
                    )}
                  >
                    {BLOCKED_KEYWORD_CHANNEL[c]}
                  </div>
                );
              })}
            </div>
          );
        },
      },
      { title: "Blocked by", field: "blockedBy" },
      {
        render(row: BlockedKeywordModel) {
          return (
            <div className="flex justify-end">
              <Tooltip title="Edit blocked keyword" arrow placement="left">
                <Link
                  data-cy="edit-number"
                  className="w-7.5 h-7.5 flex-center flex-shrink-0 bg-blue-lighter rounded-md cursor-pointer"
                  to={`${ROUTES.SETTINGS.BLOCKED_KEYWORDS.PREFIX}/${row.uuid}/edit`}
                >
                  <EditIcon color="blue" size={22} />
                </Link>
              </Tooltip>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <div className="relative h-full flex flex-col px-4 py-5">
        <div>
          <TextField
            size="sm"
            fullWidth
            className="!rounded-full !bg-white shadow-md"
            containerClass="w-80 ml-auto"
            value={search}
            icon={<SearchIcon size={16} color="primary" />}
            placeholder="Search keywords"
            clearable
            onChange={(value) => setSearch(value)}
          />
        </div>

        <DataTable
          wrapperClass="h-0 flex-grow mt-4"
          columns={columns}
          data={data}
          size="xs"
          stickyHeader
          stripped
          headerCellClass="!text-sm !px-8"
          cellClass="!px-8"
          pagination
          rowsPerPage={50}
          paginationClass="mr-16"
        />

        <div
          data-cy="create-api-token"
          className={classnames(
            "plus-action fixed bottom-5 right-5 w-13.5 hover:w-84 h-13.5 flex items-center overflow-hidden px-3",
            "bg-primary text-white text-lg font-medium uppercase whitespace-nowrap rounded-full shadow-md transition-all cursor-pointer"
          )}
          onClick={() => history.push(ROUTES.SETTINGS.BLOCKED_KEYWORDS.CREATE)}
        >
          <PlusIcon color="white" size={30} />
          <span className="ml-3">Create API Token</span>
        </div>
      </div>
    </>
  );
};

export default BlockedKeywords;
