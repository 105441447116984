import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { ROUTES } from "../../constants";
import { WhatsappLeadsDashboard } from "./WhatsappLeads";
import { MessagesDashboard } from "./Messages";
import { CallsDashboard } from "./Calls";

const QuickMetricsRouting = () => (
  <Switch>
    <Route
      path={ROUTES.QUICK_METRICS.WHATSAPP_LEADS}
      component={WhatsappLeadsDashboard}
    />
    <Route path={ROUTES.QUICK_METRICS.MESSAGES} component={MessagesDashboard} />
    <Route path={ROUTES.QUICK_METRICS.CALLS} component={CallsDashboard} />
    <Redirect to={ROUTES.QUICK_METRICS.INDEX} />
  </Switch>
);

export default QuickMetricsRouting;
