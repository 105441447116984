import React from "react";
import { Link, useParams } from "react-router-dom";
import { Card } from "../../../../components";
import { CloseCircleIcon } from "../../../../components/icons";
import { ROUTES } from "../../../../constants";
import { ErrorManagementService } from "../../../../services/error-management.service";
import { ToastService } from "../../../../services";
import { formatDateTime } from "../../../../utils/helpers";

export const CurrentErrorDetails = () => {
  //hooks
  const params = useParams<{ id: string }>();

  //state
  const [issue, setIssue] = React.useState<any>();

  React.useEffect(() => {
    ErrorManagementService.search({ id: params.id })
      .then((res) => {
        setIssue(res?.results[0]);
      })
      .catch((err) => {
        ToastService.showHttpError(err, "Could not resolve Issue Details.");
      });
  }, [params]);

  return (
    <div className="relative z-100 w-full h-full flex flex-col">
      <div className="flex items-center justify-between bg-blue-semidark text-white py-1.5 px-6">
        <div className="font-semibold text-lg uppercase pt-3 pb-2">
          Error Details
        </div>
        <Link to={ROUTES.DEVELOPER.CURRENT_ERRORS.INDEX}>
          <CloseCircleIcon color="white" size={32} className="cursor-pointer" />
        </Link>
      </div>
      <div className="h-0 flex-grow overflow-auto mt-10 mx-26">
        <Card>
          <h1 className="text-blue font-bold text-2xl">
            {issue?.error_category.replace(/([A-Z])/g, " $1").trim()}
          </h1>
          <div className="flex flex-row gap-2 !text-xs mt-4 mb-6">
            <span>
              <strong>REF ID</strong> {issue?.reference_id}
            </span>
            {/* <span><strong>| WABA</strong> {issue?.waba}</span> */}
            <span>
              <strong>| {formatDateTime(issue?.created)}</strong>
            </span>
          </div>
          <div className="h-[1px] border-solid bg-[#00000029]" />
          <p className="text-sm my-3 font-bold">Error Description:</p>
          <p className="text-sm">{issue?.customer_error_message}</p>
          <p className="text-sm mt-6 mb-4 font-bold text-success">
            How to fix it:
          </p>
          <p className="text-sm">{issue?.error_resolution}</p>
        </Card>
      </div>
    </div>
  );
};
