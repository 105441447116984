import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from "axios";
import { ROUTES, CONFIG } from "../constants";
import store from "../redux/store";
import { finishLoading, startLoading } from "../redux/actions";
import { getToken } from "../redux/selectors";
import { AuthService } from "./auth.service";

const http = axios.create({
  baseURL: `${CONFIG.API_SERVER}/`,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'x-csrftoken',
  withCredentials: true,
  headers: {
    "Content-type": "application/json",
  },
});

const httpResponseHandler = (response: AxiosResponse) => {
  return response.data;
};

const httpErrorHandler = (err: AxiosError, extra: any = {}) => {
  const response = err?.response;

  if (!extra.noAuthErrRouting && response?.status === 401) {
    //@ts-ignore
    if (response?.data?.code === "totp_session_required"
      //@ts-ignore
      || response?.data?.code === "totp_code_mismatch"
      //@ts-ignore
      || response?.data?.code === "totp_not_setup") {
      window.location.href = ROUTES.AUTH.CONFIRM_TOTP;
      return;
    } else {
      window.location.href = ROUTES.AUTH.LOGIN;
      return;
    }
  }
  throw err;
};

export const setHeaders = headers => {
  http.defaults.headers.common = {
    ...http.defaults.headers.common,
    ...headers,
  }
}

export class HttpService {
  static head(url: string, params: any = {}, headers: any = {}, hasSpinner = true, extra={}) {
    return HttpService.request('HEAD', url, { params, headers }, hasSpinner, extra);
  }

  static get(url: string, params: any = {}, headers: any = {}, hasSpinner = true, extra={}) {
    return HttpService.request('GET', url, { params, headers }, hasSpinner, extra);
  }

  static post(url: string, body: any = {}, headers: any = {}, hasSpinner = true, extra={}) {
    return HttpService.request('POST', url, { data: body, headers }, hasSpinner, extra);
  }

  static put(url: string, body: any = {}, headers: any = {}, hasSpinner = true, extra={}) {
    return HttpService.request('PUT', url, { data: body, headers }, hasSpinner, extra);
  }

  static patch(url: string, body: any = {}, headers: any = {}, hasSpinner = true, extra={}) {
    return HttpService.request('PATCH', url, { data: body, headers }, hasSpinner, extra);
  }

  static delete(url: string, body: any = {}, headers: any = {}, hasSpinner = true, extra={}) {
    return HttpService.request('DELETE', url, { data: body, headers }, hasSpinner, extra);
  }

  static request(method: Method, url: string, options: AxiosRequestConfig, hasSpinner = true, extra={}): Promise<any> {
    if (hasSpinner) {
      store.dispatch(startLoading());
    }

    return http
      .request({
        ...options,
        method,
        url,
      })
      .then(httpResponseHandler)
      .catch(err => httpErrorHandler(err, extra))
      .finally(() => {
        store.dispatch(finishLoading());
      });
  };
}
