import React, { FC } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import {
  LogoIcon,
  LogoOutlineIcon,
  LogoTextIcon,
} from "../../../components/icons";
import { ROUTES } from "../../../constants";

const NoAccount: FC = () => {
  return (
    <div className="auth-layout w-full h-screen flex">
      <div
        className="w-full bg-no-repeat bg-cover bg-center flex items-center"
        style={{
          backgroundImage: `url(/assets/images/auth-bg.png)`,
        }}
      >
        <div className="w-175 ml-75">
          <div className="flex items-center mb-4">
            <LogoIcon size={24} color={["white", "blue-dark"]} />
            <LogoTextIcon className="ml-1" size={100} color="white" />
          </div>
          <p
            className="text-5xl font-bold text-white text-left leading-tight mb-10"
            style={{ lineHeight: "1.2" }}
          >
            Oh no!
          </p>
          <p
            className="text-2xl  text-white text-left leading-tight"
            style={{ lineHeight: "1.2" }}
          >
            It seems you don't have an account with us . Please contact
            <a
              href="mailto:support@sudonum.com"
              className="text-primary font-bold"
            >
              {" "}
              support@sudonum.com{" "}
            </a>
            to get you signed up.
          </p>

          <p className="text-2xl font-bold text-white text-left leading-tight mt-10">
            Made a mistake?{" "}
            <Link to={ROUTES.AUTH.LOGIN} className="text-primary">
              Log In
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default NoAccount;
