export class CurrentErrorModel {
  id: string;
  created: string;
  customer_error_message: string;
  error_category: string;
  error_message: string;
  error_object_type: string;
  error_resolution: string;
  reference_id: string;

  constructor(init: any = {}) {
    const data = {
      id: "",
      created: "",
      customer_error_message: "",
      error_category: "",
      error_message: "",
      error_object_type: "",
      error_resolution: "",
      reference_id: "",
      ...init,
    };

    this.id = data.id;
    this.created = data.created;
    this.customer_error_message = data.customer_error_message;
    this.error_category = data.error_category;
    this.error_message = data.error_message;
    this.error_object_type = data.error_object_type;
    this.error_resolution = data.error_resolution;
    this.reference_id = data.reference_id;
  }

  isNew(): boolean {
    const current = new Date();
    const comp = new Date(this.created);
    const timeDifference = current.getTime() - comp.getTime();
    const fullDiff = Math.floor(timeDifference / (1000 * 60));
    return fullDiff < 30;
  }
}
