import React from "react";
import { TwoFactorInput } from "../../../components/TwoFactorInput";
import { LogoIcon, LogoTextIcon } from "../../../components/icons";
import { ROUTES } from "../../../constants";
import { AuthService, ToastService } from "../../../services";
import SimpleLayout from "../../../layouts/SimpleLayout";

const ConfirmTotp = () => {
  const otpText = (
    <div className="flex flex-col">
      <p
        className="text-40p font-bold text-white text-left leading-tight"
        style={{ lineHeight: "1.2" }}
      >
        Your 2FA session has expired.
      </p>
      <p className="text-white text-19p mt-4">
        Please <span className="text-primary">get another token</span> from your
        <br />
        authenticator app.
      </p>
    </div>
  );
  const submitTFA = (token) => {
    AuthService.totpSession(token)
      .then(() => {
        ToastService.success(
          <>
            <span className="text-success mr-1">Successfully</span> sent your
            request.
          </>
        );
        window.location.href = ROUTES.DASHBOARD;
      })
      .catch((err) => {
        ToastService.error("Invalid token.");
      });
  };

  return (
    <SimpleLayout text={otpText} contentClass="w-85">
      <h3 className="text-white text-22p font-bold mb-4">
        Please enter your OTP
      </h3>
      <TwoFactorInput
        secondary
        onComplete={(token) => {
          submitTFA(token);
        }}
      />
    </SimpleLayout>
  );
};

export default ConfirmTotp;
