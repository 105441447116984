import React, { FC } from "react";
import { IIconProps } from "./types";
import { Icon } from "./Icon";

export const QuickMetricsIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={24} baseHeight={24} {...props}>
    {(width, height, color) => (
      <svg
        width={width}
        height={height}
        viewBox="0 0 17.474 15.414"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Icon_ionic-md-stats"
          data-name="Icon ionic-md-stats"
          transform="translate(-5.625 -4.5)"
        >
          <path
            id="Path_3901"
            data-name="Path 3901"
            d="M12.375,4.5h3.177V19.914H12.375Z"
            transform="translate(-1.984)"
            fill={color}
          />
          <path
            id="Path_3902"
            data-name="Path 3902"
            d="M5.625,23.625H8.8v5.56H5.625Z"
            transform="translate(0 -9.271)"
            fill={color}
          />
          <path
            id="Path_3903"
            data-name="Path 3903"
            d="M19.125,19.125H22.3v8.737H19.125Z"
            transform="translate(-3.969 -7.948)"
            fill={color}
          />
          <path
            id="Path_3904"
            data-name="Path 3904"
            d="M25.875,12.375h3.177v13.5H25.875Z"
            transform="translate(-5.953 -5.964)"
            fill={color}
          />
        </g>
      </svg>
    )}
  </Icon>
);
