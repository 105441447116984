import { AuthService, setHeaders, AudioService, ToastService } from "../../services";
import { LANGUAGES } from "../../constants";
import { AUDIO_TYPE } from "../enums";
import React from "react";

export const isAuthenticated = cb => {
  AuthService.csrf().then(({ csrf }) => {
    setHeaders({ "X-CSRFToken": csrf });
    AuthService.ping().then(() => cb(true)).catch(() => cb(false));
  });
};

export const toPlainText = (html: string): string => {
  const el = document.createElement('div');
  el.innerHTML = html;
  const text = el.textContent;
  el.remove();
  return text;
};

export const copyToClipboard = async (text: string, format = 'text/plain', isToast = true): Promise<void> => {
  if (navigator.clipboard) {
    await navigator.clipboard.writeText(text);
    if (isToast) {
      ToastService.success(<><span className="text-success mr-1">Successfully</span> copied to clipboard.</>);
    }
  } else {
    const _copyToClipboard = (e) => {
      e.preventDefault();
      e.clipboardData.setData(format, text);
      document.removeEventListener('copy', _copyToClipboard);
      if (isToast) {
        ToastService.success(<><span className="text-success mr-1">Successfully</span> copied to clipboard.</>);
      }
    };
    document.addEventListener('copy', _copyToClipboard);
    document.execCommand('copy');
  }
};

export const fround = (value: number, precision = 2) => {
  const mul = Math.pow(10, precision);
  return Math.round(value * mul) / mul;
};

export const getFileInfo = (file: string | File) => {
  if (!file || typeof file !== 'string') {
    return file;
  }

  let tokens = file.split(/[/\\]+/g);
  const name = tokens[tokens.length - 1];
  tokens = name.split(/\W+/);
  const ext = tokens.length > 1 ? tokens[tokens.length - 1] : undefined;

  const mimetypes = {
    png: 'image/png',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    svg: 'image/svg+xml',
    mp3: 'audio/mpeg',
    mp4: 'video/mp4',
    mpeg: 'video/mpeg',
    pdf: 'application/pdf',
    json: 'application/json',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ppt: 'application/vnd.ms-powerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  };

  return {
    name,
    type: mimetypes[ext],
  };
};

export const filterObjectByKey = (obj, cb) => {
    return Object.fromEntries(Object.entries(obj).filter(cb))
}

export const trimObject = (obj, filters = [undefined]) => {
  if (!obj || typeof obj !== 'object' || Array.isArray(obj)) {
    return obj;
  }

  const result = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (!filters.includes(value)) {
      result[key] = value;
    }
  });

  return result;
};

export const uploadTTS = (text: string, type: AUDIO_TYPE): Promise<string> => {
  return AudioService.generateAudioFromText(text, LANGUAGES["English (US)"])
    .then((file) => {
      const fileName = `${type}_${text.replace(/\W/g, '').substr(0, 8).toLowerCase()}_${Date.now()}.mp3`;
      const audioFile = new File([file], fileName, { lastModified: new Date().getTime(), type: file.type });
      return AudioService.upload({ name: fileName, type, file: audioFile })
        .then((audio) => audio.name)
        .catch((err) => {
          ToastService.showHttpError(err, 'Uploading audio file failed');
          return null;
        });
    }).catch((err) => {
      ToastService.showHttpError(err, 'Generating audio failed');
      return null;
    });
};
