import React from "react";
import { Button } from "../../../components";
import {
  BarChartOutlineIcon,
  DocumentsIcon,
  HashTagIcon,
  LightBulbOnIcon,
  PhoneInTalkIcon,
  WhatsAppIcon,
} from "../../../components/icons";
import { AuthService } from "../../../services";
import BlockLink from "../../../components/BlockLink";

const onClick = (redirect: string = "") => {
  AuthService.readme_login(redirect).then((res) => {
    window.open(res.url);
  });
};

const DeveloperDocumentation = () => {
  return (
    <div className="developer-documentation px-10 py-9">
      <h1 className="text-3xl text-center">
        Let's get{" "}
        <span className="text-primary font-semibold">you started</span>
      </h1>

      <div className="grid grid-cols-12 gap-4 mt-8">
        <div className="col-span-12 md:col-span-8 xl:col-span-6 bg-opacity-mask mask-shape1 bg-gradient-to-b from-[#1A477F] to-[#1C56A1] text-white rounded-md shadow-md p-6">
          <div className="relative">
            <h2 className="text-2xl font-bold">
              Getting you <span className="text-primary">started</span>
            </h2>
            <div className="xs:flex items-start">
              <div className="mr-1">
                <div className="w-6 h-1 bg-primary mt-1" />
                <p className="text-xs font-medium mt-2">
                  While working through the Getting Started guides use our API
                  Explorer to browse the APIs and try them out without writing a
                  line of code.
                </p>
              </div>
              <Button
                className="flex-shrink-0 text-sm rounded-full shadow-md px-6 max-xs:mt-4 max-xs:mx-auto"
                color="primary"
                onClick={() => onClick("/")}
              >
                Let's get started
              </Button>
            </div>
          </div>
        </div>

        <div className="col-span-12 sm:col-span-4 xl:col-span-3 bg-red hover:bg-blue rounded-md shadow-md px-4 pt-4 pb-2 group">
          <BlockLink
            icon={<PhoneInTalkIcon size={20} />}
            color="bg-blue"
            heading="Call Tracking"
            subHeading="Read & learn more about our voice product"
            onClick={() => {
              onClick("/docs/voice-call");
            }}
          />
        </div>

        <div className="col-span-12 sm:col-span-4 xl:col-span-3 bg-white hover:bg-blue  rounded-md shadow-md px-4 pt-4 pb-2 group">
          <BlockLink
            icon={<HashTagIcon size={20} />}
            color="bg-primary"
            heading="All about numbers"
            subHeading="Everything you need to know about Our numbers"
            onClick={() => {
              onClick("/docs/number");
            }}
          />
        </div>

        <div className="col-span-12 sm:col-span-4 xl:col-span-3 bg-white hover:bg-blue  rounded-md shadow-md px-4 pt-4 pb-2 group">
          <BlockLink
            icon={<LightBulbOnIcon size={20} />}
            color="bg-purple"
            heading="Get some insights"
            subHeading="Learn more about our number insights"
            onClick={() => {
              onClick("/docs/number-insights");
            }}
          />
        </div>

        <div className="col-span-12 md:col-span-8 xl:col-span-6 bg-opacity-mask mask-shape2 bg-[#1a4e8e] text-white rounded-md shadow-md p-6">
          <div className="relative xs:flex">
            <div>
              <h2 className="text-2xl font-bold">
                Explore our <span className="text-primary">API's</span>
              </h2>
              <div className="mr-1">
                <div className="w-6 h-1 bg-primary mt-1" />
                <p className="text-xs font-medium mt-2">
                  You will need an API account with us to do this, email
                  <a
                    className="text-primary font-semibold mx-1"
                    href="mailto:support@sudonum.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    support@sudonum.com
                  </a>
                  to request one if you don't already have one.
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-center flex-shrink-0 xs:ml-2 max-xs:mt-4">
              <Button
                className="w-full xs:w-44 text-sm rounded-full shadow-md"
                color="primary"
                onClick={() => onClick("/reference/api-token-auth-1")}
              >
                Explore API's
              </Button>
              <a
                href="mailto:support@sudonum.com"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  className="w-full xs:w-44 text-sm rounded-full shadow-md mt-2"
                  color="primary"
                  variant="outline"
                >
                  Pop us a mail
                </Button>
              </a>
            </div>
          </div>
        </div>

        <div className="col-span-12 sm:col-span-4 xl:col-span-3 bg-white hover:bg-blue  rounded-md shadow-md px-4 pt-4 pb-2 group">
          <BlockLink
            icon={<DocumentsIcon size={20} />}
            color="bg-black"
            heading="Documentation"
            subHeading="Need to read something? Check our all documentation"
            onClick={() => {
              onClick("/docs");
            }}
          />
        </div>

        <div className="col-span-12 sm:col-span-8 md:col-span-9 lg:col-span-7 xl:col-span-5 flex items-center bg-white text-center rounded-md shadow-md p-6">
          <div className="flex-shrink-0">
            <div className="w-10 h-10 flex-center bg-primary text-white rounded-xl mx-auto">
              <DocumentsIcon size={20} />
            </div>
            <h3 className="text-md text-primary font-bold mt-2">Webhooks</h3>
          </div>
          <div className="flex-shrink-0 w-1 h-7 bg-primary mx-3" />
          <div>
            <p className="text-xs text-left font-medium mt-1">
              If Features are superpowers, Webhooks are invisible minions used
              to trigger events in your system when things happen in our system.
            </p>
            <Button
              className="text-sm rounded-full shadow-md mt-2"
              color="primary"
              onClick={() => onClick("/docs/webhooks")}
            >
              Wow, I need to know more
            </Button>
          </div>
        </div>

        <div className="col-span-12 sm:col-span-4 xl:col-span-3 bg-white hover:bg-blue  rounded-md shadow-md px-4 pt-4 pb-2 group">
          <BlockLink
            icon={<BarChartOutlineIcon size={20} />}
            color="bg-sky"
            heading="GA4 Integration"
            subHeading="Looking to integrate your GA4 Project with Sudonum? Then this is the
            doc for you."
            onClick={() => {
              onClick("/docs/ga4");
            }}
          />
        </div>

        <div className="col-span-12 sm:col-span-8 md:col-span-8 lg:col-span-6 xl:col-span-4 bg-white hover:bg-blue  rounded-md shadow-md px-4 pt-4 pb-2 group">
          <BlockLink
            icon={<WhatsAppIcon size={26} />}
            color="bg-[#69D35F]"
            heading="WhatsApp Lead Tracking"
            subHeading="Your one stop for anything WhatsApp related. From Chat intros to web
            hooks"
            onClick={() => {
              onClick("/docs/whatsapp-chat-intros");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DeveloperDocumentation;
