import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import { Preloader } from "./providers";
import {
  MessageBoxContainer,
  SpinnerContainer,
  ToastContainer,
} from "./components";
import { IdleTimerProvider } from "./providers/InactivityProvider";

import FullLayout from "./layouts/FullLayout";
import { ROUTES } from "./constants";

import Login from "./pages/Auth/Login";
import ConfirmEmail from "./pages/Auth/ConfirmEmail";
import SuccessfulActivateAccount from "./pages/Auth/SuccessfulActivateAccount";
import ResetPassword from "./pages/Auth/ResetPassword";

import ConfirmTotp from "./pages/Auth/ConfirmTotp";
import OnboardRouting from "./pages/Onboard/routing";
import CallsRouting from "./pages/Calls/routing";
import MessagesRouting from "./pages/Messages/routing";
import NumbersRouting from "./pages/Numbers/routing";
import DeveloperRouting from "./pages/Developer/routing";
import SettingsRouting from "./pages/Settings/routing";
import WhatsappRouting from "./pages/Whatsapp/routing";
import Options from "./pages/Options";
import QuickMetricsRouting from "./pages/QuickMetrics/routing";
import NoAccount from "./pages/Auth/NoAccount";
import InactivityAlert from "./components/InactivityAlert";
import DashboardRouting from "./pages/Dashboard/routing";

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat, sans-serif",
  },
});

const Pages = () => {
  const default_page = ROUTES.DASH.INDEX;

  return (
    <Preloader>
      <Route path={ROUTES.ONBOARD.PREFIX} component={OnboardRouting} />
      <IdleTimerProvider>
        <InactivityAlert />
        <FullLayout>
          <Switch>
            <Redirect path={ROUTES.DASHBOARD} to={default_page} />
            <Route path={ROUTES.DASH.PREFIX} component={DashboardRouting} />
            <Route
              path={ROUTES.QUICK_METRICS.PREFIX}
              component={QuickMetricsRouting}
            />
            <Route path={ROUTES.CALLS.PREFIX} component={CallsRouting} />
            <Route path={ROUTES.MESSAGES.PREFIX} component={MessagesRouting} />
            <Route path={ROUTES.NUMBERS.PREFIX} component={NumbersRouting} />
            <Route
              path={ROUTES.DEVELOPER.PREFIX}
              component={DeveloperRouting}
            />
            <Route path={ROUTES.WHATSAPP.PREFIX} component={WhatsappRouting} />
            <Route path={ROUTES.SETTINGS.PREFIX} component={SettingsRouting} />
            {/*<Route path={ROUTES.OPTIONS.PREFIX} component={Options} />*/}

            <Redirect to={ROUTES.DASHBOARD} />
          </Switch>
        </FullLayout>
      </IdleTimerProvider>
    </Preloader>
  );
};

const App = () => (
  <ThemeProvider theme={theme}>
    <Router>
      <Switch>
        <Route path={ROUTES.AUTH.LOGIN} component={Login} />
        <Route path={ROUTES.AUTH.NO_ACCOUNT} component={NoAccount} />
        <Route path={ROUTES.AUTH.CONFIRM_EMAIL} component={ConfirmEmail} />
        <Route
          path={ROUTES.AUTH.VERIFIED}
          component={SuccessfulActivateAccount}
        />
        <Route path={ROUTES.AUTH.RESET_PASSWORD} component={ResetPassword} />
        <Route path={ROUTES.AUTH.CONFIRM_TOTP} component={ConfirmTotp} />
        {Pages()}
      </Switch>
    </Router>
    <ToastContainer />
    <MessageBoxContainer />
    <SpinnerContainer />
  </ThemeProvider>
);

export default App;
