import React, { FC, useMemo, useState } from "react";
import {
  FormControl,
  IAbstractInputControlProps,
  Input,
} from "../../components/ReactiveForm";
import { SmsIcon } from "../../components/icons";
import { CONFIG } from "../../constants";
import { SmsCalculator } from "../../utils/helpers";

interface ISmsInputProps extends IAbstractInputControlProps {
  className?: string;
  control: FormControl;
  maxLengthPerMessage?: number;
}

export const SmsInput: FC<ISmsInputProps> = ({
  className = "",
  control,
  maxLengthPerMessage = CONFIG.MAX_SMS_LENGTH,
  ...inputProps
}) => {
  const smsLength = useMemo(
    () => SmsCalculator.getCount(control.value),
    [control.value]
  );
  const [smsMaxLength, setSmsMaxLength] = useState<number>(maxLengthPerMessage);

  React.useEffect(() => {
    setSmsMaxLength(smsLength.maxCharCount);
  }, [smsLength]);

  return (
    <div className={className}>
      <Input
        control={control}
        type="textarea"
        fullWidth
        size="sm"
        minRows={4}
        maxRows={8}
        placeholder="Type your cool message here"
        helperText="You only have 160 characters per message, if you type more than that we will split your message up into multiple SMS's"
        helperTextClass="text-gray"
        {...inputProps}
      />

      <div className="flex items-center mt-1">
        <div className="flex items-center text-blue text-md font-semibold">
          <SmsIcon className="mr-1" size={20} />
          <span className="mb-0.5">{smsLength.numberOfSMS}</span>
        </div>

        <div className="flex items-center font-semibold text-xs text-blue ml-auto">
          <span>Characters:</span>
          <span className="min-w-18 text-right ml-1">
            <span
              className={
                smsLength.totalLength > smsMaxLength ? "text-pink" : ""
              }
            >
              {smsLength.totalLength}
            </span>
            /{smsMaxLength}
          </span>
        </div>
      </div>
    </div>
  );
};
