import React from "react";
import { LogoIcon, LogoTextIcon } from "../../../components/icons";
import SimpleLayout from "../../../layouts/SimpleLayout";
import { SSOOptions } from "../../../components";
import { Link } from "react-router-dom";

const SuccessfulActivateAccount = () => {
  return (
    <SimpleLayout contentClass="w-85">
      <h1 className="tablet-landscape:text-17p text-28p text-white font-bold mb-4 tablet-landscape:text-left text-center ">
        Log into your account via SSO
      </h1>
      <SSOOptions />
    </SimpleLayout>
  );
};

export default SuccessfulActivateAccount;
