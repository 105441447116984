import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { ErrorNotificationModel, UserModel } from "../../../../utils/types";
import {
  Form,
  FormControl,
  useForm,
  Validators,
} from "../../../../components/ReactiveForm";

import {
  Button,
  Card,
  Chip,
  Chips,
  Input,
  RadioGrouping,
} from "../../../../components";
import { MenuItem, Switch } from "@mui/material";
import { ErrorManagementService } from "../../../../services/error-management.service";
import { getActiveOrganization } from "../../../../redux/selectors";
import { useSelector } from "react-redux";
import { UserService } from "../../../../services";
import { SEND_PERIOD } from "../../../../utils/enums";
import { formatHour } from "../../../../utils/helpers";
import { ConfirmationModal } from "../../../../parts";

export interface IErrorNotificationFormInitParams {
  getFormData: () => any;
}

export interface IErrorNotificationFormProps {
  errorNotification?: ErrorNotificationModel;
  confirmCancel?: boolean;
  setForm?: (params: IErrorNotificationFormInitParams) => void;
  onClose?: () => void;
}

const ErrorNotificationForm: FC<IErrorNotificationFormProps> = ({
  errorNotification,
  confirmCancel,
  setForm = () => {},
  onClose = (bypass?: boolean) => {},
}) => {
  const organization = useSelector(getActiveOrganization);

  const [form, formData] = useForm<{
    org_user_uuid: FormControl;
    send_period: FormControl;
    error_categories: FormControl;
    gmt_hour: FormControl;
  }>({
    org_user_uuid: new FormControl("", [Validators.required()]),
    send_period: new FormControl(SEND_PERIOD.IMMEDIATE, [
      Validators.required(),
    ]),
    error_categories: new FormControl([], [Validators.required()]),
    gmt_hour: new FormControl(0),
  });

  // org_user_uuid
  const [users, setUsers] = useState<UserModel[]>([]);

  useEffect(() => {
    if (!organization) {
      setUsers([]);
      return;
    }

    UserService.search(organization.uuid, "").then(
      (data) => {
        setUsers(data);
        if (!errorNotification) {
          form.patch({
            org_user_uuid: data[0].relationship.uuid,
          });
        }
      },
      (err) => {
        setUsers([]);
      }
    );
  }, [organization, errorNotification]);

  // error_categories

  const [errorCategoriesOptions, setErrorCategoriesOptions] = useState([]);

  useEffect(() => {
    ErrorManagementService.getCategories().then((res) => {
      setErrorCategoriesOptions(res);

      if (errorNotification) {
        form.patch({
          org_user_uuid: errorNotification.org_user_uuid,
          send_period: errorNotification.send_period,
          error_categories: errorNotification.error_categories,
          gmt_hour:
            errorNotification.gmt_hour !== null
              ? errorNotification.gmt_hour.toString()
              : "0",
        });
        form.controls.org_user_uuid.disable();
      } else {
        const firstCategory = res.length > 0 ? res[0].name : "";
        form.patch({
          error_categories: firstCategory ? [firstCategory] : [],
          gmt_hour: "0",
        });
      }
    });
  }, [errorNotification, form, users]);

  const getFormData = React.useCallback(() => {
    if (!form.validate()) return null;
    return form.getFormData();
  }, []);

  React.useEffect(() => {
    setForm({ getFormData });
  }, [formData]);

  const onToggleErrorCategory = (category: string) => {
    const current = form.controls.error_categories.value || []; // safety check
    let updated: string[];

    if (current.includes(category)) {
      updated = current.filter((c: string) => c !== category);
    } else {
      updated = [...current, category];
    }

    form.controls.error_categories.patch(updated);
  };
  return (
    <>
      <Form formGroup={form}>
        <Card className="mb-8">
          <h3 className={"!font-semibold text-lg pb-4"}>
            Notification Settings
          </h3>
          <div className="grid grid-cols-2 gap-8">
            <div>
              <Input
                type="select"
                control={form.controls.send_period}
                fullWidth
                label="How frequently would you like to be notified?"
                labelClass="text-sm pb-2"
                inputClass="!text-sm !font-semibold"
              >
                {(Object.values(SEND_PERIOD) as string[]).map((value) => (
                  <MenuItem key={value} className="!text-xs" value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Input>

              {form.controls.send_period.value === SEND_PERIOD.DAILY && (
                <div className="-mt-8">
                  <Input
                    type="select"
                    control={form.controls.gmt_hour}
                    fullWidth
                    placeholder={"Gmt"}
                    inputClass="!text-sm !font-semibold"
                  >
                    {Array.from({ length: 24 }, (_, i) => i).map((value) => (
                      <MenuItem
                        key={value}
                        className="!text-xs"
                        value={value.toString()}
                      >
                        {formatHour(value)}
                      </MenuItem>
                    ))}
                  </Input>
                </div>
              )}
            </div>
          </div>
        </Card>
        <Card className="mb-8">
          <p className="!font-semibold pb-5">
            Which errors would you like to be notified about?
          </p>
          <div>
            {errorCategoriesOptions.length > 0 ? (
              <div className="grid grid-cols-5">
                <div className="col-span-3">
                  {errorCategoriesOptions.map((option, i) => {
                    const isChecked =
                      form.controls.error_categories.value.includes(
                        option.name
                      );
                    return (
                      <div
                        key={i}
                        className="flex flex-row justify-between pb-5"
                      >
                        <div className="flex flex-col">
                          <span className="text-sm mt-2">{option.name}</span>
                          {/* <span className="text-xs">
                            This is a friendly text description
                          </span> */}
                        </div>
                        <Switch
                          value={option.name}
                          checked={isChecked}
                          onChange={() => onToggleErrorCategory(option.name)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Card>
        <Card>
          <p className="!font-semibold">How would you like to be notified?</p>
          <RadioGrouping
            className="py-4"
            value={"email"}
            onValueChange={() => {}}
            options={[{ label: "Email", value: "email" }]}
          />
          <div className="grid grid-cols-2 gap-8">
            <Input
              type="select"
              control={form.controls.org_user_uuid}
              fullWidth
              label="Who would you like to notify?"
              labelClass="text-sm pb-2"
              inputClass="!text-sm !font-semibold"
              placeholder="Please Select a Sudonum user"
            >
              {users.map((option) => (
                <MenuItem
                  key={option.uuid}
                  className="!text-xs"
                  value={option.relationship.uuid}
                >
                  {option.email}
                </MenuItem>
              ))}
            </Input>
          </div>
        </Card>
      </Form>
      {confirmCancel && (
        <ConfirmationModal
          title={"Confirm Cancel"}
          message={
            <p className="text-base">
              You have <span className="font-bold">unsaved changes.</span> Are
              you sure you would like to cancel and close this confirmation
              window?
            </p>
          }
          onClose={onClose}
        />
      )}
    </>
  );
};

export default ErrorNotificationForm;
