import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { ROUTES } from "../../constants";
import ApiTokens from "./ApiTokens";
import ApiTokenCreate from "./ApiTokens/ApiTokenCreate";
import DeveloperDocumentation from "./Documentation";
import { CurrentErrors } from "./CurrentErrors";
import Webhooks from "./Webhooks";
import { WebhookCreate } from "./Webhooks/WebhookCreate";
import { WebhookEdit } from "./Webhooks/WebhookEdit";

import { CurrentErrorDetails } from "./CurrentErrors/CurrentErrorDetails";
import ErrorNotifications from "./ErrorNotifications";
import { ErrorNotificationCreate } from "./ErrorNotifications/ErrorNotificationCreate";
import { ErrorNotificationEdit } from "./ErrorNotifications/ErrorNotificationEdit";
import ApiTokenEdit from "./ApiTokens/ApiTokenEdit";

const DeveloperRouting = () => (
  <Switch>
    <Redirect
      exact
      from={ROUTES.DEVELOPER.PREFIX}
      to={ROUTES.DEVELOPER.INDEX}
    />
    <Route
      path={ROUTES.DEVELOPER.DOCUMENTATION}
      component={DeveloperDocumentation}
    />
    <Route
      path={ROUTES.DEVELOPER.API_TOKENS.CREATE}
      component={ApiTokenCreate}
    />
    <Route path={ROUTES.DEVELOPER.API_TOKENS.EDIT} component={ApiTokenEdit} />
    <Route path={ROUTES.DEVELOPER.API_TOKENS.INDEX} component={ApiTokens} />

    <Route
      path={ROUTES.DEVELOPER.ERROR_NOTIFICATIONS.CREATE}
      component={ErrorNotificationCreate}
    />
    <Route
      path={ROUTES.DEVELOPER.ERROR_NOTIFICATIONS.EDIT}
      component={ErrorNotificationEdit}
    />
    <Route
      path={ROUTES.DEVELOPER.ERROR_NOTIFICATIONS.INDEX}
      component={ErrorNotifications}
    />
    <Route
      path={ROUTES.DEVELOPER.CURRENT_ERRORS.DETAILS}
      component={CurrentErrorDetails}
    />
    <Route
      path={ROUTES.DEVELOPER.CURRENT_ERRORS.INDEX}
      component={CurrentErrors}
    />
    <Route path={ROUTES.DEVELOPER.WEBHOOKS.CREATE} component={WebhookCreate} />
    <Route path={ROUTES.DEVELOPER.WEBHOOKS.EDIT} component={WebhookEdit} />
    <Route path={ROUTES.DEVELOPER.WEBHOOKS.INDEX} component={Webhooks} />
  </Switch>
);

export default DeveloperRouting;
