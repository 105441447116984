import React, { FC } from "react";
import { IIconProps } from "./types";
import { Icon } from "./Icon";

export const FileTextIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={15.39} baseHeight={18.987} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 15.39 18.987">
        <g
          id="Icon_feather-file-text"
          data-name="Icon feather-file-text"
          transform="translate(-5.5 -2.5)"
        >
          <path
            id="Path_3768"
            data-name="Path 3768"
            d="M14.994,3H7.8A1.8,1.8,0,0,0,6,4.8v14.39a1.8,1.8,0,0,0,1.8,1.8H18.591a1.8,1.8,0,0,0,1.8-1.8V8.4Z"
            fill="none"
            stroke={color}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
          />
          <path
            id="Path_3769"
            data-name="Path 3769"
            d="M21,3V8.4h5.4"
            transform="translate(-6.006)"
            fill="none"
            stroke={color}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
          />
          <path
            id="Path_3770"
            data-name="Path 3770"
            d="M19.195,19.5H12"
            transform="translate(-2.403 -6.607)"
            fill="none"
            stroke={color}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
          />
          <path
            id="Path_3771"
            data-name="Path 3771"
            d="M19.195,25.5H12"
            transform="translate(-2.403 -9.01)"
            fill="none"
            stroke={color}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
          />
          <path
            id="Path_3772"
            data-name="Path 3772"
            d="M13.8,13.5H12"
            transform="translate(-2.403 -4.205)"
            fill="none"
            stroke={color}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
          />
        </g>
      </svg>
    )}
  </Icon>
);
