import React, { useCallback, useState, useEffect, useRef } from "react";
import { Modal, TextField } from "../../../components";
import { CheckCircleIcon, SearchIcon } from "../../../components/icons";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveOrganization,
  getOrganizations,
} from "../../../redux/selectors";
import { OrganizationModel } from "../../../utils/types";
import { UserService } from "../../../services";
import { setActiveOrganization } from "../../../redux/actions";

const AccountSelector: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [search, setSearch] = useState("");
  const textFieldRef = useRef<HTMLInputElement | null>(null);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const activeOrganization = useSelector(getActiveOrganization);
  const organizations = useSelector(getOrganizations);

  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, []);

  const setNewOrganization = (item: OrganizationModel) => {
    UserService.modifyProfile({ default_org: item.uuid }, false).then(
      (data) => {
        dispatch(setActiveOrganization(item));
      }
    );
    onClose();
  };

  const onSearch = (value) => {
    setSearch(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setActiveIndex((prev) =>
        prev === null ? 0 : Math.min(prev + 1, filteredOrganizations.length - 1)
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setActiveIndex((prev) => (prev === null ? 0 : Math.max(prev - 1, 0)));
    } else if (e.key === "Enter") {
      if (activeIndex !== null && filteredOrganizations[activeIndex]) {
        setNewOrganization(filteredOrganizations[activeIndex]);
      }
    }
  };

  const filteredOrganizations = organizations.filter((org) =>
    org.name.toLowerCase().includes(search.toLowerCase())
  );

  useEffect(() => {
    if (listRef.current) {
      const activeItem = listRef.current.children[activeIndex as number];
      if (activeItem) {
        activeItem.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  }, [activeIndex, filteredOrganizations]);

  return (
    <Modal title={"Switch Account"} size="md" onClose={onClose}>
      <div className="flex flex-col pb-5">
        <div className="py-5 font-medium">
          <p>Please select or search for an account from the following list.</p>
        </div>
        <div className="pb-5">
          <TextField
            fullWidth
            className="!rounded-full !bg-gray-100"
            icon={<SearchIcon size={16} color="primary" />}
            placeholder="Search for an account"
            clearable
            value={search}
            onChange={onSearch}
            onKeyDown={handleKeyDown}
            inputRef={textFieldRef}
          />
        </div>
        <div>
          <div className="flex flex-row font-bold justify-between p-5 cursor-pointer border-b border-gray-200">
            {activeOrganization.name}
            <CheckCircleIcon className="mr-80" color={"blue"} />
          </div>
        </div>
        <div
          ref={listRef}
          tabIndex={0}
          onKeyDown={handleKeyDown}
          className="h-65 overflow-auto"
        >
          {filteredOrganizations.map((org, index) => (
            <div
              onClick={() => setNewOrganization(org)}
              className={`p-5 cursor-pointer hover:bg-gray-f4 border-b border-gray-200 ${
                index === activeIndex
                  ? "bg-blue-dark hover:text-black text-white"
                  : ""
              }`}
              key={org.uuid}
            >
              {org.name}
            </div>
          ))}
          {filteredOrganizations.length === 0 && search && (
            <p className="text-gray-500 text-center py-2">No results found</p>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AccountSelector;
