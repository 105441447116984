import React, { FC } from "react";
import classnames from "classnames";
import {
  LogoIcon,
  LogoOutlineIcon,
  LogoTextIcon,
} from "../../components/icons";

export interface IAuthLayoutProps {
  contentClass?: string;
  contentWrapperClass?: string;
}

const AuthLayout: FC<IAuthLayoutProps> = ({
  contentClass = "",
  contentWrapperClass = "",
  children,
}) => {
  return (
    <div className="auth-layout w-full h-screen flex">
      <div
        className="w-full flex-center bg-no-repeat bg-cover bg-center"
        style={{
          backgroundImage: `url(/assets/images/auth-bg.png)`,
        }}
      >
        <div className="w-100 hidden tablet-landscape:block">
          <div className="flex items-center mb-4">
            <LogoIcon size={24} color={["white", "blue-dark"]} />
            <LogoTextIcon className="ml-1" size={100} color="white" />
          </div>
          <div className="flex items-center justify-center">
            <p
              className="text-5xl font-bold text-white text-left leading-tight"
              style={{ lineHeight: "1.2" }}
            >
              Log in to see your{" "}
              <span className="text-primary">human-to-human connections</span>{" "}
            </p>
          </div>
        </div>
        <div className="tablet-landscape:block hidden">
          <div
            className="h-150 w-150 ml-10 bg-no-repeat bg-cover bg-center flex-center"
            style={{
              backgroundImage: `url(/assets/images/auth-form-container.png)`,
              backgroundSize: "contain",
            }}
          >
            <div className={classnames("max-w-full py-10 ml-20", contentClass)}>
              {children}
            </div>
          </div>
        </div>
        <div className="tablet-landscape:hidden">
          <div>
            <div
              className="h-200 w-120 bg-no-repeat bg-cover bg-center flex-center"
              style={{
                backgroundImage: `url(/assets/images/auth-form-container-mobile.png)`,
                backgroundSize: "cover",
              }}
            >
              <div className={classnames("max-w-full py-10", contentClass)}>
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
