import React, { FC, ReactNode, useMemo } from "react";
import classnames from "classnames";
import {
  LogoIcon,
  LogoOutlineIcon,
  LogoTextIcon,
} from "../../components/icons";
import { useLocation } from "react-router";
import qs from "qs";

export interface ISimpleLayoutProps {
  text?: ReactNode;

  contentClass?: string;
  contentWrapperClass?: string;
}

const defaultText = (
  <div className="flex items-center justify-center">
    <p
      className="text-5xl font-bold text-white text-left leading-tight"
      style={{ lineHeight: "1.2" }}
    >
      Log in to see your{" "}
      <span className="text-primary">human-to-human connections</span>{" "}
    </p>
  </div>
);

const SimpleLayout: FC<ISimpleLayoutProps> = ({
  contentClass = "",
  contentWrapperClass = "",
  text = defaultText,
  children,
}) => {
  return (
    <div className="auth-layout w-full h-screen flex">
      <div
        className="w-full flex-center bg-no-repeat bg-cover bg-center"
        style={{
          backgroundImage: `url(/assets/images/auth-bg.png)`,
        }}
      >
        <div className="w-100 hidden tablet-landscape:block">
          <div className="flex items-center mb-4">
            <LogoIcon size={24} color={["white", "blue-dark"]} />
            <LogoTextIcon className="ml-1" size={100} color="white" />
          </div>
          {text}
        </div>
        <div className="tablet-landscape:block hidden">
          <div className="w-120 bg-white flex-center ml-10 border border-gray-500 rounded-[52px] bg-opacity-66">
            <div className={classnames("max-w-full py-20", contentClass)}>
              {children}
            </div>
          </div>
        </div>
        <div className="tablet-landscape:hidden">
          <div>
            <div
              className="h-180 w-100 bg-no-repeat bg-cover bg-center flex-center"
              style={{
                backgroundImage: `url(/assets/images/auth-form-container-mobile.png)`,
                backgroundSize: "cover",
              }}
            >
              <div className={classnames("max-w-full py-10", contentClass)}>
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleLayout;
