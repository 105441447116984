import React, { FC } from "react";
import { IconButton, Input } from "@mui/material";
import { IAbstractInputControlProps, InputWrapper } from "../ReactiveForm";
import { CloseCircleIcon } from "../icons";

export interface ITextFieldProps {
  type?: string;
  value?: string | number;
  placeholder?: string;
  min?: number;
  max?: number;
  maxLength?: number;
  minRows?: number;
  maxRows?: number;
  clearable?: boolean;
  onFocus?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
}

export const TextField: FC<ITextFieldProps & IAbstractInputControlProps> = ({
  name = "",
  type = "text",
  value,
  min,
  max,
  minRows,
  maxRows,
  placeholder = "",
  lpIgnore = true,
  clearable,
  onChange = () => {},
  onFocus,
  onKeyDown,
  inputRef,
  ...wrapperProps
}) => {
  return (
    <InputWrapper {...wrapperProps} value={value}>
      {({ className, containerClass, disabled, readonly, onBlur }) => (
        <Input
          className={containerClass}
          classes={{ input: className }}
          name={name}
          type={type}
          value={value || ""}
          placeholder={placeholder}
          multiline={type === "textarea"}
          inputProps={{
            min,
            max,
            ...(lpIgnore ? { "data-lpignore": true } : {}),
            maxLength: max,
          }}
          endAdornment={
            clearable &&
            value && (
              <CloseCircleIcon
                className="mr-2 cursor-pointer"
                color="#EAEAEA"
                size={24}
                onClick={() => onChange("")}
              />
            )
          }
          minRows={minRows}
          maxRows={maxRows}
          readOnly={readonly}
          disabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          inputRef={inputRef}
          onKeyDown={onKeyDown}
          onChange={(e) => onChange(e.target.value)}
        />
      )}
    </InputWrapper>
  );
};
