import classnames from "classnames";
import { debounce } from "lodash";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  DataTable,
  IDataTableColumn,
  Modal,
  StatisticsTile,
  TextField,
} from "../../../../components";
import {
  SearchIcon,
  WhatsAppDeliveredIcon,
  WhatsAppFailedIcon,
  WhatsAppOptedOutIcon,
  WhatsAppReadIcon,
  WhatsAppSentIcon,
} from "../../../../components/icons";
import { getActiveOrganization } from "../../../../redux/selectors";
import {
  ToastService,
  WACampaignsService,
  WAMessagesService,
} from "../../../../services";
import { ISearchCampaignMessageRequest } from "../../../../utils/dto";
import { CAMPAIGN_MESSAGE_STATUS } from "../../../../utils/enums";
import { formatDateTime, formatPhoneNumber } from "../../../../utils/helpers";
import {
  FilterModel,
  WACampaignsLogModel,
  WACampaignsModel,
  WAMessagesModel,
} from "../../../../utils/types";
import { CampaignViewModal } from "../CampaignViewModal";
import { CampaignsOptedOutModal } from "../CampaignsOptedOutModal";
import { CampaignsSummaryFilter } from "../CampaignsSummaryFilter";

interface ICampaignsSummaryModal {
  onClose: (result?: any) => void;
  campaign: WACampaignsModel;
}

export const CampaignsSummaryModal: FC<ICampaignsSummaryModal> = ({
  onClose,
  campaign,
}) => {
  //org selector
  const organization = useSelector(getActiveOrganization);

  //data table
  const [page, setPage] = useState<number>(0);
  const [logMessages, setLogMessages] = useState<WAMessagesModel[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);

  //state
  const [log, setLog] = useState<WACampaignsLogModel>(null);
  const [campaignViewModal, setCampaignViewModal] = useState<boolean>(false);
  const [optedOutModal, setOptedOutModal] = useState<boolean>(false);

  //search bar state
  const [filters, setFilters] = useState<FilterModel>();
  const [search, setSearch] = useState("");
  const [searchWaNumber, setSearchWaNumber] = useState("");

  const debouncedSetFilter = useCallback(
    debounce((filter) => setSearchWaNumber(filter), 500),
    []
  );

  const onSearchChange = (search) => {
    setSearch(search);
    debouncedSetFilter(search);
  };

  const columns = useMemo<IDataTableColumn<WAMessagesModel>[]>(() => {
    return [
      {
        title: "From WhatsApp Number",
        field: "from_whatsapp_number",
        tooltip: { title: "From WhatsApp Number" },
        render(row: WAMessagesModel) {
          return (
            <Link to={`/numbers/${row.from_whatsapp_number}`}>
              <div className="text-primary text-11p !font-bold">
                {formatPhoneNumber(row.from_whatsapp_number)}
              </div>
            </Link>
          );
        },
      },
      {
        title: "Contact WhatsApp Number",
        field: "to_whatsapp_number",
        tooltip: { title: "Contact WhatsApp Number" },
        render(row: WAMessagesModel) {
          return formatPhoneNumber(row.to_whatsapp_number);
        },
      },
      {
        title: "Status",
        field: "campaign_sent_status",
        tooltip: { title: "Status" },
        render(row: WAMessagesModel) {
          return (
            <span
              className={classnames(
                "p-2 rounded-md",
                {
                  "text-[#7ED93D] !font-semibold bg-[#DFEDD5]":
                    row.status === CAMPAIGN_MESSAGE_STATUS.READ,
                },
                {
                  "text-[#2680EB] !font-semibold bg-[#D9E6F7]":
                    row.status === CAMPAIGN_MESSAGE_STATUS.DELIVERED,
                },
                {
                  "text-[#FE1722] !font-semibold bg-[#FFD5D7]":
                    row.status === CAMPAIGN_MESSAGE_STATUS.FAILED,
                }
              )}
            >
              {row.status}
            </span>
          );
        },
      },
      {
        title: "Date Sent",
        field: "campaign_list",
        tooltip: { title: "Date Sent" },
        render(row: WAMessagesModel) {
          return formatDateTime(row.created);
        },
      },
    ];
  }, [filters, page, organization]);

  useEffect(() => {
    WACampaignsService.getCampaignLogs(campaign.campaign_log.uuid, {}, false)
      .then((res) => {
        const log = res;
        setLog(log);
      })
      .catch((err) => {
        ToastService.error(
          "Could not find a campaign log associated with this campaign."
        );
      });
  }, [campaign]);

  const loadMessages = useCallback((query) => {
    setLoading(true);
    WAMessagesService.search(query, false)
      .then((data) => {
        setLogMessages(data.results);
        setTotalCount(data.count);
        setLoading(false);
      })
      .catch(() => {
        setLogMessages([]);
        setTotalCount(0);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!log) return;

    const query: ISearchCampaignMessageRequest = {
      campaign_log_uuid: log.uuid,
      page: page + 1,
      page_size: 10,
    };

    if (filters?.value) {
      query.status = filters.value;
    }

    if (searchWaNumber) {
      query.to_whatsapp_number = searchWaNumber;
    }
    loadMessages(query);
  }, [searchWaNumber, columns, log]);

  return (
    <>
      <Modal
        size="full"
        title={"View Campaign"}
        contentClass="bg-[#F8F8F8] !px-7 !py-4"
        onClose={onClose}
      >
        <div className="h-full flex flex-col px-4 pt-4">
          <div className="flex flex-row flex-wrap">
            <StatisticsTile
              color="#2680EB"
              backdropClass="bg-[#D9E6F7]"
              value={
                log?.messages_sent_count +
                log?.messages_delivered_count +
                log?.messages_failed_count
              }
              label="Total Sent"
              tooltip="Total sent refers to the amount of contacts your campaign was sent to."
            >
              <WhatsAppSentIcon size={30} color={"#2680EB"} />
            </StatisticsTile>
            <StatisticsTile
              color="#79D7EE"
              backdropClass="bg-[#E2F8FD]"
              value={log?.messages_delivered_count}
              label="Delivered"
              tooltip="Delivered refers to the amount of contacts that received your campaign. This total can be less that the total sent due to contacts not having data enabled."
            >
              <WhatsAppDeliveredIcon size={30} color={"#79D7EE"} />
            </StatisticsTile>
            <StatisticsTile
              color="#FE1722"
              backdropClass="bg-[#FFD5D7]"
              value={log?.messages_failed_count}
              label="Failed"
              tooltip="Messages failed means the contact did not receive your message at all. This could be because of a system error, or that they have previously opted out and cannot receive any campaigns from this WABA"
            >
              <WhatsAppFailedIcon size={30} color={"#FE1722"} />
            </StatisticsTile>
            <StatisticsTile
              onClick={() => setOptedOutModal(true)}
              color="#FE1722"
              backdropClass="bg-[#FFD5D7]"
              value={log?.contact_count_unsubscribed}
              label="Opted Out"
              tooltip="Opt outs are the amount times a contact used the “unsubscribe” or “Don’t message me again” options. To ensure they are removed from the list and won’t receive another campaign."
            >
              <WhatsAppOptedOutIcon size={30} color={"#FE1722"} />
            </StatisticsTile>

            <div className="grid place-items-center mb-4">
              <Button
                className="rounded-full shadow-md capitalize text-[12px]"
                color="blue"
                onClick={() => setCampaignViewModal(true)}
              >
                View Campaign
              </Button>
            </div>
          </div>

          <div className="flex justify-between items-center my-4">
            <CampaignsSummaryFilter filters={filters} onChange={setFilters} />
            <TextField
              data-cy="wa-campaigns-search"
              size="sm"
              fullWidth
              className="!rounded-full !bg-white shadow-md"
              containerClass="w-80 ml-auto"
              value={search}
              icon={<SearchIcon size={16} color="primary" />}
              placeholder="Search WA Numbers"
              clearable
              onChange={(text) => onSearchChange(text.split(" ").join(""))}
            />
          </div>

          <DataTable
            columns={columns}
            data={logMessages}
            size="sm"
            stripped
            stickyHeader
            page={page}
            rowsPerPage={10}
            pagination
            selfPagination
            headerCellClass="!text-13p"
            cellClass="!text-11p"
            onPaginationChange={(page) => setPage(page)}
            serverSide={false}
            totalCount={totalCount}
            extLoading={loading}
          />
        </div>
      </Modal>

      {campaignViewModal && (
        <CampaignViewModal
          campaign={campaign}
          onClose={() => setCampaignViewModal(false)}
        />
      )}

      {optedOutModal && (
        <CampaignsOptedOutModal
          campaignLog={log}
          onClose={() => setOptedOutModal(false)}
        />
      )}
    </>
  );
};
