import React, { FC, useState } from "react";
import classnames from "classnames";
import { Card, Input, TextField } from "../../../components";
import {
  TimeIcon,
  LogoIcon,
  SmsIcon,
  SmsReceiveIcon,
  SmsSendWithTailIcon,
} from "../../../components/icons";
import { AddTagModal } from "../../../parts";
import { MessageBoxService, ToastService } from "../../../services";
import { SMS_DIRECTION, SMS_STATUS } from "../../../utils/enums";
import {
  copyToClipboard,
  formatPhoneNumber,
  getCountryCodeFromNumberToLowerCase,
} from "../../../utils/helpers";
import { SmsRecordModel } from "../../../utils/types";

export interface IMessageDetailProps {
  message: SmsRecordModel;
  onClose: (result?: any) => void;
}

const MessageDetail: FC<IMessageDetailProps> = ({ message, onClose }) => {
  const [tags, setTags] = useState<string[]>([
    "Important",
    "Follow up Required",
  ]);
  const [showAddTagModal, setShowAddTagModal] = useState<boolean>(false);

  const onCopyUuid = () => {
    copyToClipboard(message.uuid);
  };

  const onAddTags = (newTags: []) => {
    setTags([...tags, ...newTags]);
    ToastService.success(
      <>
        <span className="text-success mr-1">Successfully</span> added a tag.
      </>
    );
    onClose(
      new SmsRecordModel({
        ...message,
        tags: [...tags, ...newTags],
      })
    );
  };

  const onRemoveTag = (index: number) => {
    MessageBoxService.confirm({
      title: "Delete confirmation",
      message: (
        <>
          Are you sure you want to delete <b>'{tags[index]}'</b> tag?
        </>
      ),
    }).then((result) => {
      if (result) {
        const newTags = [...tags];
        newTags.splice(index, 1);
        setTags(newTags);

        ToastService.success(
          <>
            <span className="text-success mr-1">Successfully</span> removed a
            tag.
          </>
        );
        onClose(
          new SmsRecordModel({
            ...message,
            tags: newTags,
          })
        );
      }
    });
  };

  return (
    <>
      <div className="w-full h-full space-x-4 flex flex-row">
        <Card>
          <div className="flex flex-col w-70">
            <div className="text-md font-semibold">Message Details</div>
            <div
              className="text-11p hover:text-blue-light  has-hover-action cursor-pointer"
              onClick={onCopyUuid}
            >
              <span className="font-semibold transition-all">UUID</span>
              <span className="transition-all">: {message.uuid}</span>
              <i className="far fa-clone text-sm ml-2 pnh-transparent transition-all" />
            </div>

            <div className="flex flex-col space-y-2 pt-10">
              <div className="flex flex-col py-2 px-4 space-y-1 bg-blue-lighter rounded-lg">
                <span className="text-xs text-blue">Sender</span>
                <div className="flex flex-row ">
                  <div className="">
                    <img
                      src={`/assets/images/flags/${getCountryCodeFromNumberToLowerCase(
                        message.from_number
                      )}.png`}
                      alt=""
                      width={18}
                      height={5}
                    />
                  </div>
                  <span className="ml-2 text-sm font-semibold text-blue">
                    {formatPhoneNumber(message.from_number)}
                  </span>
                </div>
              </div>

              <div className="flex flex-col py-2 px-4 space-y-1 bg-blue-dark bg-opacity-20 rounded-lg">
                <span className="text-xs text-blue-dark">Sudo Number</span>
                <div className="flex flex-row ">
                  <div className="">
                    <img
                      src={`/assets/images/flags/${getCountryCodeFromNumberToLowerCase(
                        message.from_number
                      )}.png`}
                      alt=""
                      width={18}
                      height={5}
                    />
                  </div>
                  <span className="ml-2 text-sm font-semibold text-blue-dark">
                    {formatPhoneNumber(message.number)}
                  </span>
                </div>
              </div>

              <div className="flex flex-col py-2 px-4 space-y-1 bg-primary bg-opacity-19 rounded-lg">
                <span className="text-xs text-primary">Target Number</span>
                <div className="flex flex-row ">
                  <div className="">
                    <img
                      src={`/assets/images/flags/${getCountryCodeFromNumberToLowerCase(
                        message.from_number
                      )}.png`}
                      alt=""
                      width={18}
                      height={5}
                    />
                  </div>
                  <span className="ml-2 text-sm font-semibold text-primary">
                    {formatPhoneNumber(message.target_number)}
                  </span>
                </div>
              </div>
            </div>

            <div className="flex flex-row space-x-2 pt-8">
              {!!message.number && (
                <div className="relative flex flex-col items-center text-center bg-blue bg-opacity-5 rounded-md p-2 flex-1">
                  <div className="absolute -top-3 w-7 h-7 flex-center rounded-full bg-gray-f8">
                    <TimeIcon color="blue" size={15} />
                  </div>
                  <div className="text-xs mt-1">Date</div>
                  <div className="text-blue-light text-xs">
                    <span>{message.time.substr(0, 10)}</span>&nbsp;
                    <span className="font-semibold">
                      {message.time.substr(11, 8)}
                    </span>
                  </div>
                </div>
              )}

              <div
                className={classnames(
                  "relative flex flex-col items-center text-center bg-primary-lighter text-primary rounded-md p-2 flex-1",
                  {
                    "!bg-[#ecf5dd] !text-success":
                      message.status === SMS_STATUS.DELIVERED ||
                      message.status === SMS_STATUS.SENT ||
                      message.status === SMS_STATUS.RECEIVED,
                  },
                  {
                    "!bg-[#fed8da] !text-danger-light": message.status.includes(
                      SMS_STATUS.BLOCKED
                    ),
                  }
                )}
              >
                <div className="absolute -top-3 w-7 h-7 flex-center rounded-full bg-inherit">
                  <SmsIcon size={15} />
                </div>
                <div className="text-body text-xs mt-1">Message Status</div>
                <div className="font-semibold text-xs">
                  {message.status === SMS_STATUS.BLOCKED_SENDER
                    ? "Blocked Sender"
                    : message.status === SMS_STATUS.BLOCKED_KEYWORD
                    ? "Blocked Keyword(s)"
                    : message.status}
                </div>
              </div>
            </div>
          </div>
        </Card>

        <div className="flex flex-col space-y-2 w-full">
          <Card>
            <div className="flex flex-col w-full">
              <span className="text-md font-semibold">Message Content</span>
              <div className="border-t border-gray-300 my-4"></div>
            </div>
            <span>{message.message}</span>
          </Card>

          {!!Object.keys(message.meta).length && (
            <Card
              title="Extra Info"
              headerClass="text-blue-dark text-lg font-semibold px-1"
            >
              <div className="grid grid-cols-2 gap-4">
                {Object.entries(message.meta).map((info, i) => (
                  <TextField
                    // containerClass="w-110"
                    fullWidth
                    key={i}
                    label={info[0]}
                    value={info[1]}
                    readonly
                  />
                ))}
              </div>
            </Card>
          )}
        </div>
      </div>

      {showAddTagModal && (
        <AddTagModal
          tags={tags}
          onSubmit={onAddTags}
          onClose={() => setShowAddTagModal(false)}
        />
      )}
    </>
  );
};

export default MessageDetail;
