import { Input } from "@mui/material";
import classNames from "classnames";
import React, { useState, useRef, useEffect } from "react";

interface ITwoFactorInput {
  onComplete?: (value: string) => void;
  secondary?: boolean;
}

export const TwoFactorInput = ({
  onComplete,
  secondary = false,
}: ITwoFactorInput) => {
  const ref = useRef<HTMLInputElement>();
  const [code, setCode] = useState("");
  const [touched, setTouched] = useState<boolean>(false);

  const handleInputChange = (event) => {
    const value = event.target.value.replace(/\D/g, "").slice(0, 6);
    setCode(value);

    if (value.length === 6) {
      onComplete(value);
    }
  };

  useEffect(() => {
    ref.current.focus();
  }, []);

  return (
    <div className="flex items-center relative max-w-[27rem]">
      <div className="flex absolute">
        {[...Array(6)].map((_, index) => (
          <div
            key={index}
            className={classNames(
              "w-12 h-12 text-center bg-white rounded flex justify-center items-center mr-2",
              { "mr-5": index === 2 },
              { "bg-gray-100": secondary },
              { "border-2 border-blue": touched && index === code.length }
            )}
          >
            {code[index] || " "}
          </div>
        ))}
      </div>
      <Input
        inputRef={ref}
        autoFocus
        type="tel"
        value={code}
        onInput={handleInputChange}
        className="relative z-10 w-full opacity-0 min-h-[3.75rem]"
        onFocus={() => setTouched(true)}
        onBlur={() => setTouched(false)}
      />
    </div>
  );
};
