import React, { createContext, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import { AuthService } from "../services";
import { getActiveOrganization } from "../redux/selectors";

type IdleTimerContextType = {
  resetTimer: () => void;
  originalTimeout: number;
  showWarning: boolean;
  timeLeft: number;
  handleLogout: () => void;
};

const IdleTimerContext = createContext<IdleTimerContextType | undefined>(
  undefined
);

const TWELVE_HOURS_IN_MINUTES = 720

export const IdleTimerProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const organization = useSelector(getActiveOrganization);
  const [timeout, setTimeout] = useState<number>(TWELVE_HOURS_IN_MINUTES)
  const [remaining, setRemaining] = useState<number>(0)
  const [showWarning, setShowWarning] = useState(false);

  const onPrompt = () => {
    setShowWarning(true);
  };

  const onIdle = () => {
    AuthService.logout();
    setShowWarning(false);
  };

  const { reset, pause, getRemainingTime } = useIdleTimer({
    timeout: (timeout + 1) * 60 * 1000,
    onIdle,
    onPrompt,
    promptBeforeIdle: 60 * 1000,
    debounce: 500,
  });

  React.useEffect(() => {
    if (organization)
      setTimeout(organization.idle_timeout || TWELVE_HOURS_IN_MINUTES);
  }, [organization]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  const resetWarning = () => {
    setShowWarning(false);
    reset();
  };

  return (
    <IdleTimerContext.Provider
      value={{
        resetTimer: resetWarning,
        showWarning,
        originalTimeout: timeout,
        timeLeft: remaining,
        handleLogout: onIdle,
      }}
    >
      {children}
    </IdleTimerContext.Provider>
  );
};

export const useIdleTimerContext = (): IdleTimerContextType => {
  const context = useContext(IdleTimerContext);
  if (!context) {
    throw new Error(
      "useIdleTimerContext must be used within an IdleTimerProvider"
    );
  }
  return context;
};
