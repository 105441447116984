export class ErrorNotificationModel {
  org_user_uuid: string;
  user_email: string;
  send_period: string;
  error_categories: string[];
  gmt_hour: number | null;
  updated: string;

  constructor(init: any = {}) {
    const data = {
      org_user_uuid: "",
      user_email: "",
      send_period: "",
      error_categories: [],
      gmt_hour: null,
      updated: "",
      ...init,
    };

    this.org_user_uuid = data.org_user_uuid;
    this.user_email = data.user_email;
    this.send_period = data.send_period;
    this.error_categories = data.error_categories;
    this.gmt_hour = data.gmt_hour;
    this.updated = data.updated;
  }
}
