import { formatPaginatedResult, trimObject } from "../utils/helpers";
import { CurrentErrorModel, ErrorNotificationModel } from "../utils/types";
import { HttpService } from "./http.service";

export class ErrorManagementService {
  static search(query = {}, showSpinner = true) {
    return HttpService.get(
      "/errors/tracking/",
      trimObject(query),
      {},
      showSpinner
    ).then((res) => formatPaginatedResult(CurrentErrorModel, res));
  }

  static find(issueID: string, showSpinner = true) {
    return HttpService.get(
      `/errors/tracking/${issueID}/`,
      {},
      {},
      showSpinner
    ).then((res) => new CurrentErrorModel(res));
  }

  static getErrorNotification(errorRecipientId: string, showSpinner = true) {
    return HttpService.get(
      `/errors/recipients/${errorRecipientId}/`,
      {},
      {},
      showSpinner
    ).then((res) => new ErrorNotificationModel(res));
  }

  static createErrorNotification(data: any = {}, showSpinner = false) {
    return HttpService.post(
      "/errors/recipients/",
      trimObject(data),
      {},
      showSpinner
    ).then((res) => new ErrorNotificationModel(res));
  }

  static updateErrorNotication(
    errorRecipientId: string,
    data: any = {},
    showSpinner = false
  ) {
    return HttpService.patch(
      `/errors/recipients/${errorRecipientId}/`,
      trimObject(data),
      {},
      showSpinner
    ).then((res) => new ErrorNotificationModel(res));
  }

  static deleteErrorNotification(
    errorRecipientId: string,
    showSpinner = false
  ) {
    return HttpService.delete(
      `/errors/recipients/${errorRecipientId}/`,
      {},
      showSpinner
    );
  }

  static getErrorNotifications(showSpinner = true) {
    return HttpService.get("/errors/recipients", {}, {}, showSpinner).then(
      (res) => res.results
    );
  }

  static getCategories(showSpinner = true) {
    return HttpService.get("/errors/categories", {}, {}, showSpinner).then(
      (res) => res.results
    );
  }
}
