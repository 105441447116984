import React, { FC } from "react";
import { IIconProps } from "./types";
import { Icon } from "./Icon";

export const LightBulbOnIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={35.888} baseHeight={44.86} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 35.888 44.86">
        <path
          fill={color}
          d="M232.751,4.486a.748.748,0,0,0,.748-.748V.748a.748.748,0,1,0-1.5,0V3.738A.748.748,0,0,0,232.751,4.486Z"
          transform="translate(-214.807)"
        />
        <path
          fill={color}
          d="M140.078,25.734l1.5,2.589a.748.748,0,0,0,1.3-.739l0-.009-1.5-2.589a.748.748,0,0,0-1.3.739Z"
          transform="translate(-131.379 -22.308)"
        />
        <path
          fill={color}
          d="M72.981,93.365l.009,0,2.589,1.5a.748.748,0,1,0,.756-1.29l-.009,0-2.589-1.5a.748.748,0,0,0-.756,1.29Z"
          transform="translate(-70.312 -83.377)"
        />
        <path
          fill={color}
          d="M52.489,184.748a.748.748,0,0,0-.748-.748H48.751a.748.748,0,0,0,0,1.5h2.991A.748.748,0,0,0,52.489,184.748Z"
          transform="translate(-48.003 -166.804)"
        />
        <path
          fill={color}
          d="M75.587,260.07l-.009.005-2.589,1.5a.748.748,0,1,0,.739,1.3l.009-.005,2.589-1.5a.748.748,0,1,0-.739-1.3Z"
          transform="translate(-70.311 -235.676)"
        />
        <path
          fill={color}
          d="M367.319,261.575l-.009-.005-2.589-1.5a.748.748,0,1,0-.756,1.29l.009.005,2.589,1.5a.748.748,0,1,0,.756-1.29Z"
          transform="translate(-334.1 -235.675)"
        />
        <path
          fill={color}
          d="M387.741,184h-2.991a.748.748,0,0,0,0,1.5h2.991a.748.748,0,1,0,0-1.5Z"
          transform="translate(-352.601 -166.804)"
        />
        <path
          fill={color}
          d="M364.4,94.965a.748.748,0,0,0,.374-.1l2.589-1.5a.748.748,0,1,0-.739-1.3l-.009,0-2.589,1.5a.748.748,0,0,0,.374,1.4Z"
          transform="translate(-334.149 -83.376)"
        />
        <path
          fill={color}
          d="M308.371,28.6h0a.748.748,0,0,0,1.021-.274h0l1.5-2.589a.748.748,0,0,0-1.29-.756l-.005.009-1.5,2.589A.748.748,0,0,0,308.371,28.6Z"
          transform="translate(-283.699 -22.308)"
        />
        <path
          fill={color}
          d="M176.227,174.953a.748.748,0,0,0,.748-.748v-8.224h1.5a2.991,2.991,0,1,0-2.991-2.991v1.5h-1.5v-1.5a2.991,2.991,0,1,0-2.991,2.991h1.5v8.224a.748.748,0,1,0,1.5,0v-8.224h1.5v8.224A.748.748,0,0,0,176.227,174.953Zm.748-11.963a1.5,1.5,0,1,1,1.5,1.5h-1.5Zm-4.486,1.5h-1.5a1.5,1.5,0,1,1,1.5-1.5Z"
          transform="translate(-156.788 -145.047)"
        />
        <path
          fill={color}
          d="M124.613,64.016a11.963,11.963,0,0,0-8.164,21.2,2.228,2.228,0,0,1,.854,1.71v1.753a2.243,2.243,0,0,0,1.5,2.105v.885a2.229,2.229,0,0,0,.583,1.5,2.209,2.209,0,0,0,0,2.991,2.233,2.233,0,0,0,1.66,3.738.729.729,0,0,0,.615-1.149.792.792,0,0,0-.084-.129.773.773,0,0,0-.208-.136c-.022-.011-.043-.02-.066-.029a.793.793,0,0,0-.236-.047c-.007,0-.014,0-.022,0a.748.748,0,1,1,0-1.5h2.991a.748.748,0,0,0,0-1.5h-2.991a.748.748,0,1,1,0-1.5h2.991a.748.748,0,0,0,0-1.5h-2.991a.748.748,0,0,1-.748-.748v-.748h3.738a.748.748,0,0,0,0-1.5h-4.486a.748.748,0,0,1-.748-.748V86.921a3.717,3.717,0,0,0-1.4-2.867,10.467,10.467,0,0,1,7.145-18.542A10.588,10.588,0,0,1,134.5,75.9a10.406,10.406,0,0,1-3.884,8.2,3.706,3.706,0,0,0-1.35,2.911v1.667a.748.748,0,0,1-.748.748h-2.243a.748.748,0,1,0,0,1.5h1.5v.748a.748.748,0,0,1-.748.748h-.748a.748.748,0,1,0,0,1.5h.748a.748.748,0,0,1,0,1.5h-.748a.748.748,0,1,0,0,1.5h.748a.748.748,0,0,1,0,1.5h-3.738a.748.748,0,0,0,0,1.5h2.863a2.243,2.243,0,0,1-3.791.742.748.748,0,0,0-1.117.994,3.73,3.73,0,0,0,6.468-1.855,2.221,2.221,0,0,0,.976-3.62,2.209,2.209,0,0,0,0-2.991,2.229,2.229,0,0,0,.583-1.5V90.78a2.243,2.243,0,0,0,1.5-2.105V87.008a2.248,2.248,0,0,1,.793-1.747,11.892,11.892,0,0,0,4.44-9.371A12.1,12.1,0,0,0,124.613,64.016Z"
          transform="translate(-106.088 -58.02)"
        />
      </svg>
    )}
  </Icon>
);
